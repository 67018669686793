import React, { useState, useEffect, useCallback } from "react";
import api from '../../components/api.js';  // Reemplazar importación de axios
import "./Insumos.css";
import { getPermissions } from "../../components/utils";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

function Stocks() {
  const [productos, setProductos] = useState([]); // Estado para almacenar los productos
  const [itemSeleccionado, setItemSeleccionado] = useState(null); // made by choclito
  const [modalDetalleAbierto, setModalDetalleAbierto] = useState(false);
  const [modalFiltros, setModalFiltros] = useState(false);
  const [copiaFiltros, setCopiaFiltros] = useState({});
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [codigosInternos, setCodigosInternos] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  
  // Estado para los filtros que se están editando
  const [filtrosTemp, setFiltrosTemp] = useState({
    nombre: '',
    precio_min: '',
    precio_max: '',
    codigo: '',
    lote: '',
    ubicacion: '',
    fecha_de_vencimiento_min: '',
    fecha_de_vencimiento_max: '',
    limit: 10,
    pagina: 1
  });

  // Estado para los filtros que realmente se aplican
  const [filtrosAplicados, setFiltrosAplicados] = useState({
    nombre: '',
    precio_min: '',
    precio_max: '',
    codigo: '',
    lote: '',
    ubicacion: '',
    fecha_de_vencimiento_min: '',
    fecha_de_vencimiento_max: '',
    limit: 10,
    pagina: 1
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [modalEditarItem, setModalEditarItem] = useState(false);
  const [itemEditando, setItemEditando] = useState(null);

  const getItems = useCallback(() => {
    let extra = "";
    extra = "?";
    for (const [key, value] of Object.entries(filtrosAplicados)) {
      if (value !== '') {
        extra += `${key}=${value}&`;
      }
    }
    return api.get(`/stocks${extra}`);
  }, [filtrosAplicados]);

  const fetchData = useCallback(async () => {
    try {
      console.log("Actualizando datos...");
      const response = await getItems();
      setProductos(response.data.items);
      setTotalPaginas(response.data.total_paginas);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
    }
  }, [getItems, setTotalPaginas]);

  const getIsAdmin = async () => {
    const permissions = await getPermissions();
    setIsAdmin(permissions.includes('admin'));
  };

  useEffect(() => {
    fetchData();
    getIsAdmin();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchData]);

  const getCodigosInternos = async (id) => {
    const response = await api.get(`/codigos?id_producto=${id}`);
    setCodigosInternos(response.data);
    console.log(response.data);
  };

  const handleClickItem = (producto) => {
    console.log("Item clickeado:", producto); // Para debugging
    setItemSeleccionado(producto);
    setModalDetalleAbierto(true);
    getCodigosInternos(producto.id);
  };

  const handleCerrarModalDetalle = () => {
    setModalDetalleAbierto(false);
    setItemSeleccionado(null);
  }

  const handleFiltroChange = (e) => {
    const { name, value } = e.target;
    setFiltrosTemp(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLimpiarFiltros = () => {
    const filtrosLimpios = {
      nombre: '',
      precio_min: '',
      precio_max: '',
      codigo: '',
      lote: '',
      ubicacion: '',
      fecha_de_vencimiento_min: '',
      fecha_de_vencimiento_max: '',
      limit: 10,
      pagina: 1
    };
    setFiltrosTemp(filtrosLimpios);
    setFiltrosAplicados(filtrosLimpios);
    setCopiaFiltros({});
    fetchData();
  };

  const handlePagina = (pagina) => {
    if (pagina > totalPaginas || pagina < 1) {
      return;
    }
    setPaginaActual(pagina);
    const nuevosFiltros = {...filtrosAplicados, pagina: pagina};
    setFiltrosAplicados(nuevosFiltros);
    setFiltrosTemp(nuevosFiltros);
    fetchData();
  };

  const handleEditarItem = () => {
    setItemEditando({...itemSeleccionado});
    setModalEditarItem(true);
    setModalDetalleAbierto(false);
  };

  const handleCerrarModalEditar = () => {
    setModalEditarItem(false);
    setItemEditando(null);
  };

  const handleGuardarEdicion = async () => {
    try {
      const response = await api.put(
        `/editStocks/${itemEditando.id}`,
        itemEditando
      );
      console.log("Item editado exitosamente", response);

      const historialResponse = await api.put(`/entradasProductos/${itemEditando.id_entrada}`, {
        ...itemEditando,
        id: itemEditando.id_entrada
      });
      console.log("Historial editado exitosamente", historialResponse);

      if (response.status === 200) {
        fetchData();
        handleCerrarModalEditar();
      }
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      alert("Error al guardar los cambios");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemEditando(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // --------------------------
  // Filtros
  // --------------------------

  const handleConfirmarFiltros = () => {
    // Validación de rangos
    if (filtrosTemp.precio_min && filtrosTemp.precio_max && 
        Number(filtrosTemp.precio_min) > Number(filtrosTemp.precio_max)) {
      alert('El precio mínimo no puede ser mayor que el máximo');
      return;
    }
    
    if (filtrosTemp.fecha_de_vencimiento_min && filtrosTemp.fecha_de_vencimiento_max && 
        new Date(filtrosTemp.fecha_de_vencimiento_min) > new Date(filtrosTemp.fecha_de_vencimiento_max)) {
      alert('La fecha inicial no puede ser posterior a la fecha final');
      return;
    }

    setFiltrosAplicados(filtrosTemp);
    setCopiaFiltros({...filtrosTemp});
    fetchData();
    setModalFiltros(false);
  };

  const handleEliminarItem = async (id) => {
    if (!isAdmin) {
      alert("No tienes permisos para eliminar este item");
      return;
    }
      
    setShowConfirmDialog(true);
  };

  const confirmarEliminacion = async () => {
    console.log("Eliminando item con ID:", itemSeleccionado.id);
    try {
      const entradaResponse = await api.delete(`/entradasProductos/${itemSeleccionado.id_entrada}`);
      console.log("Entrada eliminada exitosamente", entradaResponse);

      const response = await api.delete(`/stocks/${itemSeleccionado.id}`);
      if (response.status === 200) {
        setShowConfirmDialog(false);
        handleCerrarModalDetalle();
        fetchData();
      } else {
        console.error("Error al eliminar el item");
      }
    }
    catch (error) {
      console.error("Error al eliminar el item");
    }
  };

  const cancelarFiltros = () => {
    setFiltrosTemp({...copiaFiltros});
    setModalFiltros(false);
  };

  const handleAbrirFiltros = () => {
    setCopiaFiltros(filtrosTemp);
    setModalFiltros(true);
  };

  const handleDescargarPDF = async (codigo) => {
    console.log("Descargando PDF con código:", codigo);
    try {
      const response = await api.post('/generar-pdf', 
        {
          codigo: codigo
        }, 
        {
          responseType: 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `codigo-${codigo}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error("Error al descargar el PDF");
    }
  };

  return (
    <div className="inventario-container">
      <div className="contenedor-boton-filtros">
        <button 
          className="boton-filtros"
          onClick={handleAbrirFiltros}
        >
          Filtros
        </button>
      </div>

      <TableContainer component={Paper} className="tabla-container">
        <Table aria-label="tabla de stocks">
          <TableHead>
            <TableRow className="tabla-header">
              <TableCell>Código</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell align="right">Cantidad</TableCell>
              <TableCell>Ubicación</TableCell>
              <TableCell>Clasificación</TableCell>
              <TableCell align="right">Precio</TableCell>
              <TableCell align="right">IVA Total</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.length > 0 ? (
              productos.map(producto => (
                <TableRow 
                  key={producto.id} 
                  onClick={() => handleClickItem(producto)} 
                  className="tabla-row"
                >
                  <TableCell className="tabla-cell">{producto.codigo}</TableCell>
                  <TableCell className="tabla-cell">{producto.nombre}</TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    {producto.cantidad?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell">{producto.ubicacion}</TableCell>
                  <TableCell className="tabla-cell">{producto.clasificacion}</TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    ${producto.precio?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    ${producto.IVA?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    ${producto.precio_total?.toLocaleString('es-CL')}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="mensaje-sin-datos">
                  No hay datos disponibles.
                </TableCell>
              </TableRow>
            )}
            <TableRow className="total-row">
              <TableCell colSpan={7} align="right">
                <strong>Total de la página</strong>
              </TableCell>
              <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                <strong>
                  ${productos.reduce((sum, producto) => sum + producto.precio_total, 0)?.toLocaleString('es-CL')}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="pagination-container">
        <button className="pagination-button"
        onClick={() => paginaActual > 1 ? handlePagina(paginaActual - 1) : null}>
          ←
        </button>
        <div className="pagination-number">{paginaActual}</div>
        <button className="pagination-button"
        onClick={() => handlePagina(paginaActual + 1)}>
          →
        </button>
      </div>

      {modalDetalleAbierto && itemSeleccionado && (
        <div className="modal-overlay">
          <div className="modal modal-detalles modal-grande">
            <h2>Detalles del Producto</h2>
            <div className="detalles-item">
              <div className="detalles-grid">
                <div className="detalle-grupo">
                  <p><strong>Código:</strong> {itemSeleccionado.codigo}</p>
                  <p><strong>Nombre:</strong> {itemSeleccionado.nombre}</p>
                  <p><strong>Precio:</strong> ${itemSeleccionado.precio?.toLocaleString('es-CL')}</p>
                  <p><strong>Cantidad:</strong> {itemSeleccionado.cantidad}</p>
                  <p><strong>IVA:</strong> ${itemSeleccionado.IVA?.toLocaleString('es-CL')}</p>
                  <p><strong>Precio Total:</strong> ${itemSeleccionado.precio_total?.toLocaleString('es-CL')}</p>
                </div>
                
                <div className="detalle-grupo">
                  <p><strong>Lote:</strong> {itemSeleccionado.lote}</p>
                  <p><strong>Unidad/Formato:</strong> {itemSeleccionado.u_formato}</p>
                  <p><strong>N° CAS:</strong> {itemSeleccionado.n_cas}</p>
                  <p><strong>Procedencia:</strong> {itemSeleccionado.procedencia}</p>
                  <p><strong>Clasificación:</strong> {itemSeleccionado.clasificacion}</p>
                  <p><strong>Centro de Costos:</strong> {itemSeleccionado.centro_de_costos}</p>
                </div>

                <div className="detalle-grupo">
                  <p><strong>Ubicación:</strong> {itemSeleccionado.ubicacion}</p>
                  <p><strong>Fecha de Ingreso:</strong> {itemSeleccionado.fecha_de_ingreso}</p>
                  <p><strong>Fecha de Vencimiento:</strong> {itemSeleccionado.fecha_de_vencimiento}</p>
                  <p><strong>Fecha de Fabricación:</strong> {itemSeleccionado.fecha_de_fabricacion}</p>
                  <p><strong>Fecha de Factura:</strong> {itemSeleccionado.fecha_de_factura}</p>
                  <p><strong>N° Factura:</strong> {itemSeleccionado.n_factura}</p>
                  <p><strong>Sede:</strong> {itemSeleccionado.sede}</p>
                </div>
              </div>

              <div className="descripcion-seccion">
                <p><strong>Descripción:</strong></p>
                <p>{itemSeleccionado.descripcion}</p>
              </div>

              <div className="codigos-internos-seccion">
                <h3>Códigos Internos</h3>
                <table className="codigos-internos-tabla">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {codigosInternos && codigosInternos.map((codigo, index) => (
                      codigo.valid && (
                        <tr key={codigo.id}>
                          <td>{codigo.codigo}</td>
                          <td><button className="boton-pdf" onClick={() => handleDescargarPDF(codigo.codigo)}>Descargar PDF</button></td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-buttons">
              {isAdmin && (
                <>
                  <button 
                    type="button" 
                    className="boton-editar"
                    onClick={handleEditarItem}
                  >
                    Editar
                  </button>
                  <button 
                    type="button" 
                    className="boton-eliminar"
                    onClick={() => handleEliminarItem(itemSeleccionado.id)}
                  >
                    Eliminar
                  </button>
                </>
              )}
              <button 
                type="button" 
                className="boton-cerrar"
                onClick={handleCerrarModalDetalle}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmDialog && (
        <div className="modal-overlay">
          <div className="modal modal-confirmacion">
            <h2>Confirmar Eliminación</h2>
            <p>¿Está seguro que desea eliminar este item?</p>
            <div className="modal-buttons">
              <button 
                className="boton-eliminar"
                onClick={confirmarEliminacion}
              >
                Confirmar
              </button>
              <button 
                className="boton-cerrar"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {modalFiltros && (
        <div className="modal-overlay">
          <div className="modal modal-filtros">
            <h2>Filtros de Búsqueda</h2>
            <div className="filtros-grid">
              <div className="form-group">
                <label>Nombre:</label>
                <input
                  type="text"
                  name="nombre"
                  value={filtrosTemp.nombre}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por nombre"
                />
              </div>

              <div className="form-group">
                <label>Rango de Precio:</label>
                <div className="rango-inputs">
                  <input
                    type="number"
                    name="precio_min"
                    value={filtrosTemp.precio_min}
                    onChange={handleFiltroChange}
                    placeholder="Mínimo"
                  />
                  <input
                    type="number"
                    name="precio_max"
                    value={filtrosTemp.precio_max}
                    onChange={handleFiltroChange}
                    placeholder="Máximo"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Código:</label>
                <input
                  type="text"
                  name="codigo"
                  value={filtrosTemp.codigo}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por código"
                />
              </div>

              <div className="form-group">
                <label>Lote:</label>
                <input
                  type="text"
                  name="lote"
                  value={filtrosTemp.lote}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por lote"
                />
              </div>

              <div className="form-group">
                <label>Ubicación:</label>
                <input
                  type="text"
                  name="ubicacion"
                  value={filtrosTemp.ubicacion}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por ubicación"
                />
              </div>

              <div className="form-group">
                <label>Rango de Fecha de Vencimiento:</label>
                <div className="rango-inputs">
                  <input
                    type="date"
                    name="fecha_de_vencimiento_min"
                    value={filtrosTemp.fecha_de_vencimiento_min}
                    onChange={handleFiltroChange}
                  />
                  <input
                    type="date"
                    name="fecha_de_vencimiento_max"
                    value={filtrosTemp.fecha_de_vencimiento_max}
                    onChange={handleFiltroChange}
                  />
                </div>
              </div>
            </div>

            <div className="modal-buttons">
              <button 
                type="button" 
                className="boton-limpiar-filtros"
                onClick={handleLimpiarFiltros}
              >
                Limpiar Filtros
              </button>
              <button 
                type="button" 
                className="boton-aplicar-filtros"
                onClick={handleConfirmarFiltros}
              >
                Aplicar Filtros
              </button>
              <button 
                type="button" 
                className="boton-cancelar-filtros"
                onClick={cancelarFiltros}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {modalEditarItem && itemEditando && (
        <div className="modal-overlay">
          <div className="modal modal-detalles modal-grande">
            <h2>Editar Producto</h2>
            <div className="detalles-item">
              <div className="detalles-grid">
                <div className="detalle-grupo">
                  <div className="form-group">
                    <label><strong>Código:</strong></label>
                    <input
                      type="text"
                      name="codigo"
                      value={itemEditando.codigo}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Nombre:</strong></label>
                    <input
                      type="text"
                      name="nombre"
                      value={itemEditando.nombre}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Precio:</strong></label>
                    <input
                      type="number"
                      name="precio"
                      value={itemEditando.precio}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Cantidad:</strong></label>
                    <input
                      type="number"
                      name="cantidad"
                      value={itemEditando.cantidad}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                
                <div className="detalle-grupo">
                  <div className="form-group">
                    <label><strong>Lote:</strong></label>
                    <input
                      type="text"
                      name="lote"
                      value={itemEditando.lote}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Unidad/Formato:</strong></label>
                    <input
                      type="text"
                      name="u_formato"
                      value={itemEditando.u_formato}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>N° CAS:</strong></label>
                    <input
                      type="text"
                      name="n_cas"
                      value={itemEditando.n_cas}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Procedencia:</strong></label>
                    <input
                      type="text"
                      name="procedencia"
                      value={itemEditando.procedencia}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="detalle-grupo">
                  <div className="form-group">
                    <label><strong>Ubicación:</strong></label>
                    <input
                      type="text"
                      name="ubicacion"
                      value={itemEditando.ubicacion}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Fecha de Vencimiento:</strong></label>
                    <input
                      type="date"
                      name="fecha_de_vencimiento"
                      value={itemEditando.fecha_de_vencimiento}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label><strong>Sede:</strong></label>
                    <input
                      type="text"
                      name="sede"
                      value={itemEditando.sede}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="descripcion-seccion">
                <label><strong>Descripción:</strong></label>
                <textarea
                  name="descripcion"
                  value={itemEditando.descripcion}
                  onChange={handleInputChange}
                  rows="4"
                />
              </div>
            </div>

            <div className="modal-buttons">
              <button 
                type="button" 
                className="boton-guardar"
                onClick={handleGuardarEdicion}
              >
                Guardar
              </button>
              <button 
                type="button" 
                className="boton-cerrar"
                onClick={handleCerrarModalEditar}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Stocks;
