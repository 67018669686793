import React, { useState } from 'react';
import api from '../../components/api.js';
import './login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [mensaje, setMensaje] = useState({
    texto: '',
    tipo: '' // 'error' o 'exito'
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', {
        username: formData.username,
        password: formData.password,
      });
  
      // `response.data` contiene la respuesta del servidor
      const data = response.data;
  
      // Guardar el token y los permisos en localStorage
      localStorage.setItem("token", data.access_token);
      localStorage.setItem("username", data.username);
      setMensaje({
        texto: "¡Inicio de sesión exitoso!",
        tipo: "exito"
      });

      // Redirigir al usuario después del login exitoso
      window.location.reload();
      window.location.href = '/';
    } catch (error) {
      // Manejo de errores (credenciales inválidas o problemas de red)
      if (error.response && error.response.status === 401) {
        setMensaje({
          texto: "Credenciales inválidas",
          tipo: "error"
        });
      } else {
        setMensaje({
          texto: "Error al iniciar sesión",
          tipo: "error"
        });
        console.error(error);
      }
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Iniciar Sesión</h2>
        {mensaje.texto && (
          <div className={`mensaje ${mensaje.tipo}`}>
            {mensaje.texto}
          </div>
        )}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Usuario:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
