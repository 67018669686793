import React, { useState, useEffect } from 'react';
import './PermissionsTable.css';
import api from '../../components/api.js';

const AdminPage = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [sedes, setSedes] = useState([]);
  const [centroCostos, setCentroCostos] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });
  const [clasificaciones, setClasificaciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSedeModalOpen, setIsSedeModalOpen] = useState(false);
  const [newSede, setNewSede] = useState('');
  const [isCentroCostoModalOpen, setIsCentroCostoModalOpen] = useState(false);
  const [isClasificacionModalOpen, setIsClasificacionModalOpen] = useState(false);
  const [newCentroCosto, setNewCentroCosto] = useState('');
  const [newClasificacion, setNewClasificacion] = useState('');
  const [isProveedorModalOpen, setIsProveedorModalOpen] = useState(false);
  const [newProveedor, setNewProveedor] = useState({
    nombre_prov: '',
    rut: '',
    giro: '',
    direccion: '',
    contacto: '',
    pais: '',
    ciudad: '',
    nombre_de_contacto: '',
    telefono: ''
  });
  const [isClienteModalOpen, setIsClienteModalOpen] = useState(false);
  const [newCliente, setNewCliente] = useState({
    nombre_cliente: '',
    rut: '',
    giro: '',
    direccion: '',
    email: '',
    pais: '',
    ciudad: '',
    nombre_contacto: '',
    telefono: ''
  });

  const generarExcel = async (tipo) => {
    try {
      const response = await api.post('/generar-excel', {
        tipo: tipo
      }, {
        responseType: 'blob'
      });
      
      // Crear URL del blob y descargar
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `tabla_exportada-${tipo}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error al generar Excel:", error);
    }
  };

  const handleDownloadOptions = () => {
    setIsExportModalOpen(true);
  };

  const handleCheckboxChange = (index, permission) => {
    const updatedUsuarios = [...usuarios];
    updatedUsuarios[index][permission] = !updatedUsuarios[index][permission];
    try {
      api.put(`/usuarios/${updatedUsuarios[index].username}`, {
        [permission]: updatedUsuarios[index][permission]
      });
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
    setUsuarios(updatedUsuarios);
  };

  const getUsuarios = async () => {
    const response = await api.get('/usuarios');
    const username = localStorage.getItem('username');
    setUsuarios(response.data.filter(usuario => usuario.username !== username));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteProveedor = async (idProveedor) => {
    try {
      await api.delete(`/proveedores/${idProveedor}`);
      getProveedores();
    } catch (error) {
      console.error('Error al eliminar proveedor:', error);
    }
  };

  const getProveedores = async () => {
    const response = await api.get('/proveedores');
    setProveedores(response.data);
  };

  const getClientes = async () => {
    const response = await api.get('/clientes');
    setClientes(response.data);
  };

  const handleDeleteCliente = async (idCliente) => {
    try {
      await api.delete(`/clientes/${idCliente}`);
      getClientes();
    } catch (error) {
      console.error('Error al eliminar cliente:', error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    if (newUser.password !== newUser.confirmPassword) {
      setNotification({
        show: true,
        message: 'Las contraseñas no coinciden',
        type: 'error'
      });
      setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
      return;
    }
    // Aquí irá la lógica para crear el usuario
    console.log('Crear usuario:', newUser);
    const response = await api.post('/register', {
      username: newUser.username, 
      password: newUser.password, 
      reportes: false, 
      stocks: false, 
      entrada: false, 
      salida: false, 
      admin: false
    });
    console.log(response);
    setNotification({
      show: true,
      message: 'Usuario creado exitosamente',
      type: 'success'
    });
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
    await getUsuarios();
    setIsModalOpen(false);
    setNewUser({ username: '', password: '', confirmPassword: '' });
  };

  const handleDeleteUser = async (username) => {
    try {
      await api.delete(`/usuarios/${username}`);
      // Actualizar la lista de usuarios después de eliminar
      getUsuarios();
      setNotification({
        show: true,
        message: 'Usuario eliminado exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
      setNotification({
        show: true,
        message: 'Error al eliminar usuario',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  // Agregar función para obtener historiales
  const getSedesYCentroCostos = async () => {
    try {
      const responseSedes = await api.get('/sedes');
      const responseCentroCostos = await api.get('/centrosdecosto');
      setSedes(responseSedes.data);
      setCentroCostos(responseCentroCostos.data);
    } catch (error) {
      console.error('Error al obtener sedes y centro de costos:', error);
    }
  };

  const handleDeleteSede = async (idSede) => {
    try {
      await api.delete(`/sedes/${idSede}`);
      // Actualizar la lista de sedes después de eliminar
      getSedesYCentroCostos();
      setNotification({
        show: true,
        message: 'Sede eliminada exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al eliminar sede:', error);
      setNotification({
        show: true,
        message: 'Error al eliminar sede',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleDeleteCentroCosto = async (idCentroCosto) => {
    try {
      await api.delete(`/centros-de-costos/${idCentroCosto}`);
      // Actualizar la lista de centros de costo después de eliminar
      getSedesYCentroCostos();
      setNotification({
        show: true,
        message: 'Centro de costo eliminado exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al eliminar centro de costo:', error);
      setNotification({
        show: true,
        message: 'Error al eliminar centro de costo',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const getClasificaciones = async () => {
    const response = await api.get('/clasificaciones');
    setClasificaciones(response.data);
  };

  const handleDeleteClasificacion = async (idClasificacion) => {
    try {
      await api.delete(`/clasificaciones/${idClasificacion}`);
      // Actualizar la lista de clasificaciones después de eliminar
      getClasificaciones();
      setNotification({
        show: true,
        message: 'Clasificación eliminada exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al eliminar clasificación:', error);
      setNotification({
        show: true,
        message: 'Error al eliminar clasificación',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleCreateSede = async (e) => {
    e.preventDefault();
    try {
      await api.post('/sedes', {
        nombre: newSede
      });
      getSedesYCentroCostos();
      setIsSedeModalOpen(false);
      setNewSede('');
      setNotification({
        show: true,
        message: 'Sede creada exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al crear sede:', error);
      setNotification({
        show: true,
        message: 'Error al crear sede',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleCreateCentroCosto = async (e) => {
    e.preventDefault();
    try {
      await api.post('/centrosdecosto', {
        nombre: newCentroCosto
      });
      getSedesYCentroCostos();
      setIsCentroCostoModalOpen(false);
      setNewCentroCosto('');
      setNotification({
        show: true,
        message: 'Centro de costo creado exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al crear centro de costo:', error);
      setNotification({
        show: true,
        message: 'Error al crear centro de costo',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleCreateClasificacion = async (e) => {
    e.preventDefault();
    try {
      await api.post('/clasificaciones', {
        nombre: newClasificacion
      });
      getClasificaciones();
      setIsClasificacionModalOpen(false);
      setNewClasificacion('');
      setNotification({
        show: true,
        message: 'Clasificación creada exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al crear clasificación:', error);
      setNotification({
        show: true,
        message: 'Error al crear clasificación',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleCreateProveedor = async (e) => {
    e.preventDefault();
    try {
      await api.post('/proveedores', newProveedor);
      getProveedores();
      setIsProveedorModalOpen(false);
      setNewProveedor({
        nombre_prov: '',
        rut: '',
        giro: '',
        direccion: '',
        contacto: '',
        pais: '',
        ciudad: '',
        nombre_de_contacto: '',
        telefono: ''
      });
      setNotification({
        show: true,
        message: 'Proveedor creado exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al crear proveedor:', error);
      setNotification({
        show: true,
        message: 'Error al crear proveedor',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleProveedorInputChange = (e) => {
    const { name, value } = e.target;
    setNewProveedor(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateCliente = async (e) => {
    e.preventDefault();
    try {
      await api.post('/clientes', newCliente);
      getClientes();
      setIsClienteModalOpen(false);
      setNewCliente({
        nombre_cliente: '',
        rut: '',
        giro: '',
        direccion: '',
        contacto: '',
        pais: '',
        ciudad: '',
        nombre_de_contacto: '',
        telefono: ''
      });
      setNotification({
        show: true,
        message: 'Cliente creado exitosamente',
        type: 'success'
      });
    } catch (error) {
      console.error('Error al crear cliente:', error);
      setNotification({
        show: true,
        message: 'Error al crear cliente',
        type: 'error'
      });
    }
    setTimeout(() => setNotification({ show: false, message: '', type: '' }), 3000);
  };

  const handleClienteInputChange = (e) => {
    const { name, value } = e.target;
    setNewCliente(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    getUsuarios();
    getSedesYCentroCostos();
    getClasificaciones();
    getProveedores();
    getClientes();
  }, []);

  return (
    <div className="table-container">
      {notification.show && (
        <div className={`notification-overlay ${notification.type}`}>
          <div className="notification-content">
            {notification.message}
          </div>
        </div>
      )}
      <table className="permissions-table">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Reportes</th>
            <th>Stocks</th>
            <th>Entrada</th>
            <th>Salida</th>
            <th>Admin</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario, index) => (
            <tr key={index}>
              <td>{usuario.username}</td>
              <td>
                <input
                  type="checkbox"
                  checked={usuario.reportes}
                  onClick={() => handleCheckboxChange(index, 'reportes')}
                  className="checkbox"
                  readOnly
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={usuario.stocks}
                  onClick={() => handleCheckboxChange(index, 'stocks')}
                  className="checkbox"
                  readOnly
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={usuario.entrada}
                  onClick={() => handleCheckboxChange(index, 'entrada')}
                  className="checkbox"
                  readOnly
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={usuario.salida}
                  onClick={() => handleCheckboxChange(index, 'salida')}
                  className="checkbox"
                  readOnly
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={usuario.admin}
                  onClick={() => handleCheckboxChange(index, 'admin')}
                  className="checkbox"
                  readOnly
                />
              </td>
              <td>
                <button
                  onClick={() => handleDeleteUser(usuario.username)}
                  style={{
                    backgroundColor: '#ff4444',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  ✕
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="button-container">
        <button 
          className="create-user-button"
          onClick={() => setIsModalOpen(true)}
        >
          Crear nuevo usuario
        </button>
        <button 
          className="export-button"
          onClick={handleDownloadOptions}
        >
          Exportar datos
        </button>
      </div>

      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <div style={{ flex: 1 }}>
          <h2>Sedes</h2>
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th style={{ width: '50px', textAlign: 'right' }}></th>
              </tr>
            </thead>
            <tbody>
              {sedes.map((sede, index) => (
                <tr key={index}>
                  <td>{sede.nombre}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      onClick={() => handleDeleteSede(sede.id)}
                      style={{
                        backgroundColor: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '5px',
                        height: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      ✕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="create-user-button"
            onClick={() => setIsSedeModalOpen(true)}
            style={{ marginTop: '10px' }}
          >
            Agregar sede
          </button>
        </div>

        <div style={{ flex: 1 }}>
          <h2>Centro de Costos</h2>
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th style={{ width: '80px', textAlign: 'right' }}></th>
              </tr>
            </thead>
            <tbody>
              {centroCostos.map((centroCosto, index) => (
                <tr key={index}>
                  <td>{centroCosto.nombre}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      onClick={() => handleDeleteCentroCosto(centroCosto.id)}
                      style={{
                        backgroundColor: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      ✕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="create-user-button"
            onClick={() => setIsCentroCostoModalOpen(true)}
            style={{ marginTop: '10px' }}
          >
            Agregar centro de costo
          </button>
        </div>

        <div style={{ flex: 1 }}>
          <h2>Clasificaciones</h2>
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th style={{ width: '80px', textAlign: 'right' }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {clasificaciones.map((clasificacion, index) => (
                <tr key={index}>
                  <td>{clasificacion.nombre}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      onClick={() => handleDeleteClasificacion(clasificacion.id)}
                      style={{
                        backgroundColor: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      ✕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="create-user-button"
            onClick={() => setIsClasificacionModalOpen(true)}
            style={{ marginTop: '10px' }}
          >
            Agregar clasificación
          </button>
        </div>
      </div>

      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <div style={{ flex: 1 }}>
          <h2>Proveedores</h2>
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th style={{ width: '80px', textAlign: 'right' }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {proveedores.map((proveedor, index) => (
                <tr key={index}>
                  <td>{proveedor.nombre_prov}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      onClick={() => handleDeleteProveedor(proveedor.id)}
                      style={{
                        backgroundColor: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      ✕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="create-user-button"
            onClick={() => setIsProveedorModalOpen(true)}
            style={{ marginTop: '10px' }}
          >
            Agregar proveedor
          </button>
        </div>

        <div style={{ flex: 1 }}>
          <h2>Clientes</h2>
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th style={{ width: '80px', textAlign: 'right' }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {clientes.map((cliente, index) => (
                <tr key={index}>
                  <td>{cliente.nombre_cliente}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      onClick={() => handleDeleteCliente(cliente.id)}
                      style={{
                        backgroundColor: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      ✕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="create-user-button"
            onClick={() => setIsClienteModalOpen(true)}
            style={{ marginTop: '10px' }}
          >
            Agregar cliente
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Crear Nuevo Usuario</h2>
            <form onSubmit={handleCreateUser}>
              <div className="form-group">
                <label>Nombre de Usuario:</label>
                <input
                  type="text"
                  name="username"
                  value={newUser.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Contraseña:</label>
                <input
                  type="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Repetir Contraseña:</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={newUser.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="create-user-button">
                  Crear Usuario
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isExportModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Exportar Datos</h2>
            <div className="export-options">
              <button onClick={() => {
                generarExcel('stocks');
                setIsExportModalOpen(false);
              }}>
                Stocks
              </button>
              <button onClick={() => {
                generarExcel('entrada_productos');
                setIsExportModalOpen(false);
              }}>
                Historial de Entrada
              </button>
              <button onClick={() => {
                generarExcel('salida_productos');
                setIsExportModalOpen(false);
              }}>
                Historial de Salida
              </button>
              <button onClick={() => {
                generarExcel('proveedores');
                setIsExportModalOpen(false);
              }}>
                Proveedores
              </button>
              <button onClick={() => {
                generarExcel('clientes');
                setIsExportModalOpen(false);
              }}>
                Clientes
              </button>
            </div>
            <div className="modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setIsExportModalOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {isSedeModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Crear Nueva Sede</h2>
            <form onSubmit={handleCreateSede}>
              <div className="form-group">
                <label>Nombre de la Sede:</label>
                <input
                  type="text"
                  value={newSede}
                  onChange={(e) => setNewSede(e.target.value)}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="create-user-button">
                  Crear Sede
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsSedeModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isCentroCostoModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Crear Nuevo Centro de Costo</h2>
            <form onSubmit={handleCreateCentroCosto}>
              <div className="form-group">
                <label>Nombre del Centro de Costo:</label>
                <input
                  type="text"
                  value={newCentroCosto}
                  onChange={(e) => setNewCentroCosto(e.target.value)}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="create-user-button">
                  Crear Centro de Costo
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsCentroCostoModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isClasificacionModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Crear Nueva Clasificación</h2>
            <form onSubmit={handleCreateClasificacion}>
              <div className="form-group">
                <label>Nombre de la Clasificación:</label>
                <input
                  type="text"
                  value={newClasificacion}
                  onChange={(e) => setNewClasificacion(e.target.value)}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="create-user-button">
                  Crear Clasificación
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsClasificacionModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isProveedorModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" style={{ 
            maxWidth: '800px',
            width: '90%',
            maxHeight: '90vh',
            overflowY: 'auto',
            padding: '30px'
          }}>
            <h2>Crear Nuevo Proveedor</h2>
            <form onSubmit={handleCreateProveedor}>
              <div style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)', 
                gap: '20px' 
              }}>
                <div className="form-group">
                  <label>Nombre del Proveedor:</label>
                  <input
                    type="text"
                    name="nombre_prov"
                    value={newProveedor.nombre_prov}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>RUT:</label>
                  <input
                    type="text"
                    name="rut"
                    value={newProveedor.rut}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Giro:</label>
                  <input
                    type="text"
                    name="giro"
                    value={newProveedor.giro}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="contacto"
                    value={newProveedor.contacto}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>País:</label>
                  <input
                    type="text"
                    name="pais"
                    value={newProveedor.pais}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Ciudad:</label>
                  <input
                    type="text"
                    name="ciudad"
                    value={newProveedor.ciudad}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Nombre del Contacto:</label>
                  <input
                    type="text"
                    name="nombre_de_contacto"
                    value={newProveedor.nombre_de_contacto}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Teléfono:</label>
                  <input
                    type="tel"
                    name="telefono"
                    value={newProveedor.telefono}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
                <div className="form-group" style={{ gridColumn: '1 / -1' }}>
                  <label>Dirección:</label>
                  <input
                    type="text"
                    name="direccion"
                    value={newProveedor.direccion}
                    onChange={handleProveedorInputChange}
                    required
                  />
                </div>
              </div>
              <div className="modal-buttons" style={{ marginTop: '20px' }}>
                <button type="submit" className="create-user-button">
                  Crear Proveedor
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsProveedorModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isClienteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" style={{ 
            maxWidth: '800px',
            width: '90%',
            maxHeight: '90vh',
            overflowY: 'auto',
            padding: '30px'
          }}>
            <h2>Crear Nuevo Cliente</h2>
            <form onSubmit={handleCreateCliente}>
              <div style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)', 
                gap: '20px' 
              }}>
                <div className="form-group">
                  <label>Nombre del Cliente:</label>
                  <input
                    type="text"
                    name="nombre_cliente"
                    value={newCliente.nombre_cliente}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>RUT:</label>
                  <input
                    type="text"
                    name="rut"
                    value={newCliente.rut}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Giro:</label>
                  <input
                    type="text"
                    name="giro"
                    value={newCliente.giro}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="contacto"
                    value={newCliente.contacto}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>País:</label>
                  <input
                    type="text"
                    name="pais"
                    value={newCliente.pais}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Ciudad:</label>
                  <input
                    type="text"
                    name="ciudad"
                    value={newCliente.ciudad}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Nombre del Contacto:</label>
                  <input
                    type="text"
                    name="nombre_de_contacto"
                    value={newCliente.nombre_de_contacto}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Teléfono:</label>
                  <input
                    type="tel"
                    name="telefono"
                    value={newCliente.telefono}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
                <div className="form-group" style={{ gridColumn: '1 / -1' }}>
                  <label>Dirección:</label>
                  <input
                    type="text"
                    name="direccion"
                    value={newCliente.direccion}
                    onChange={handleClienteInputChange}
                    required
                  />
                </div>
              </div>
              <div className="modal-buttons" style={{ marginTop: '20px' }}>
                <button type="submit" className="create-user-button">
                  Crear Cliente
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => setIsClienteModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
