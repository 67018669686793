import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import logo from '../../components/assets/logo.png';

const HomePage = () => {
  const navigate = useNavigate();
  
  const loggedIn = useMemo(() => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return false;
      
      return true;
    } catch (error) {
      console.error('Error al verificar el estado de autenticación:', error);
      return false;
    }
  }, []);

  return (
    <div className="container">
      <section className="hero-section">
        <div className="hero-content">
          <img 
            src={logo} 
            alt="Labotrade Logo" 
            className="hero-logo"
          />
          <h1 className="title">Bienvenido al sistema de inventario Labotrade</h1>
          <p className="subtitle">Sistema de inventario para el control de productos y equipos</p>
          {!loggedIn && (
            <div className="cta-container">
              <button 
                className="call-to-action" 
                onClick={() => navigate('/login')}
                type="button"
                aria-label="Iniciar sesión"
              >
                Iniciar sesión
              </button>
              <p className="cta-description">
                Accede a tu cuenta para gestionar el inventario
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
