import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Card, Table, Modal, DatePicker, Space } from 'antd';
import api from '../../components/api.js';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const { RangePicker } = DatePicker;

const Reportes = () => {
  const [compras, setCompras] = useState([]);
  const [fecha_de_ingreso, setFecha_de_ingreso] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [fecha_de_salida, setFecha_de_salida] = useState([]);
  const [modalVentasVisible, setModalVentasVisible] = useState(false);
  const [modalComprasVisible, setModalComprasVisible] = useState(false);
  const [dateRange, setDateRange] = useState(() => {
    const firstDay = moment().startOf('month');
    const lastDay = moment().endOf('month');
    return [firstDay, lastDay];
  });

  const [ventasData, setVentasData] = useState({
    labels: [],
    datasets: [{
      label: 'Ventas por Día',
      data: [],
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      pointRadius: 3,
      pointHoverRadius: 6
    }]
  });

  const [comprasData, setComprasData] = useState({
    labels: [],
    datasets: [{
      label: 'Compras por Día',
      data: [],
      borderColor: 'rgb(255, 99, 132)',
      tension: 0.1,
      pointRadius: 3,
      pointHoverRadius: 6
    }]
  });

  const [productoMasVendido, setProductoMasVendido] = useState(null);
  const [productosPorVencer, setProductosPorVencer] = useState([]);

  const getData = useCallback(async () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) return;

    const formatearFecha = (fecha) => {
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const año = fecha.getFullYear();
      return `${dia}-${mes}-${año}`;
    };

    const procesarDatos = (datos) => {
      const datosCombinados = datos.map(item => ({
        fecha: moment(item.fecha_de_ingreso || item.fecha_de_salida).toDate(),
        precio: item.precio_total
      }));

      datosCombinados.sort((a, b) => a.fecha - b.fecha);

      const datosAgrupados = datosCombinados.reduce((acc, curr) => {
        const fecha = formatearFecha(curr.fecha);
        if (!acc[fecha]) {
          acc[fecha] = {
            total: 0,
            count: 0
          };
        }
        acc[fecha].total += curr.precio;
        acc[fecha].count += 1;
        return acc;
      }, {});

      const fechasOrdenadas = Object.keys(datosAgrupados);
      const precios = fechasOrdenadas.map(fecha => 
        datosAgrupados[fecha].total
      );

      return { fechas: fechasOrdenadas, precios: precios };
    };

    try {
      let extra = `?fecha_inicio=${dateRange[0].format('YYYY-MM-DD')}&fecha_fin=${dateRange[1].format('YYYY-MM-DD')}`;

      const responseCompras = await api.get(`/reportes/historial-entrada${extra}`);
      const datosCompras = procesarDatos(responseCompras.data);
      setFecha_de_ingreso(datosCompras.fechas);
      setCompras(datosCompras.precios);

      const responseVentas = await api.get(`/reportes/historial-salida${extra}`);
      const datosVentas = procesarDatos(responseVentas.data);
      setFecha_de_salida(datosVentas.fechas);
      setVentas(datosVentas.precios);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  }, [dateRange]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const getProductoMasVendido = async () => {
    try {
      const response = await api.get('/reportes/producto-mas-vendido');
      setProductoMasVendido(response.data);
    } catch (error) {
      console.error('Error al obtener producto más vendido:', error);
    }
  };

  const getProductosPorVencer = async () => {
    try {
      const response = await api.get('/reportes/vencimiento');
      setProductosPorVencer(response.data);
    } catch (error) {
      console.error('Error al obtener productos por vencer:', error);
    }
  };

  useEffect(() => {
    getData();
    getProductoMasVendido();
    getProductosPorVencer();
  }, [getData]);

  useEffect(() => {
    setVentasData({
      labels: fecha_de_salida,
      datasets: [{
        label: 'Ventas por Día',
        data: ventas,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        pointRadius: 3,
        pointHoverRadius: 6
      }]
    });

    setComprasData({
      labels: fecha_de_ingreso,
      datasets: [{
        label: 'Compras por Día',
        data: compras,
        borderColor: 'rgb(255, 99, 132)',
        tension: 0.1,
        pointRadius: 3,
        pointHoverRadius: 6
      }]
    });
  }, [ventas, compras, fecha_de_salida, fecha_de_ingreso]);

  const columns = [
    {
      title: 'Producto',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Fecha de Vencimiento',
      dataIndex: 'fecha_de_vencimiento',
      key: 'fecha_de_vencimiento',
    },
    {
      title: 'Días Restantes',
      dataIndex: 'dias_restantes',
      key: 'dias_restantes',
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Panel de Reportes</h1>

      <div style={{ marginBottom: '20px' }}>
        <Space>
          <RangePicker 
            value={dateRange}
            onChange={handleDateRangeChange}
            format="DD-MM-YYYY"
            placeholder={['Fecha inicial', 'Fecha final']}
            allowClear={true}
          />
        </Space>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', marginBottom: '20px' }}>
        <Card 
          title="Ventas del Mes"
          extra={
            <button 
              onClick={() => setModalVentasVisible(true)}
              style={{ border: 'none', background: 'none', cursor: 'pointer' }}
            >
              🔍
            </button>
          }
        >
          <Line data={ventasData} />
        </Card>

        <Card 
          title="Compras del Mes"
          extra={
            <button 
              onClick={() => setModalComprasVisible(true)}
              style={{ border: 'none', background: 'none', cursor: 'pointer' }}
            >
              🔍
            </button>
          }
        >
          <Line data={comprasData} />
        </Card>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        <Card title="Producto Más Vendido">
          {productoMasVendido ? (
            <>
              <h3>{productoMasVendido.nombre}</h3>
              <p>Cantidad vendida: {productoMasVendido.total_vendido} unidades</p>
              <p>Total en ventas: ${new Intl.NumberFormat('es-CL').format(productoMasVendido.total_precio)}</p>
            </>
          ) : (
            <p>No hay datos disponibles</p>
          )}
        </Card>

        <Card title="Productos por Vencer">
          <Table 
            columns={columns} 
            dataSource={productosPorVencer} 
            pagination={false}
            size="small"
            rowKey="id"
          />
        </Card>
      </div>

      <Modal
        title="Ventas del Mes"
        open={modalVentasVisible}
        onCancel={() => setModalVentasVisible(false)}
        width="80%"
        footer={null}
      >
        <div style={{ height: '70vh' }}>
          <Line data={ventasData} options={{ maintainAspectRatio: false }} />
        </div>
      </Modal>

      <Modal
        title="Compras del Mes"
        open={modalComprasVisible}
        onCancel={() => setModalComprasVisible(false)}
        width="80%"
        footer={null}
      >
        <div style={{ height: '70vh' }}>
          <Line data={comprasData} options={{ maintainAspectRatio: false }} />
        </div>
      </Modal>
    </div>
  );
};

export default Reportes;
