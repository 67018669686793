import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getPermissions } from "./utils"; // Función para obtener permisos del token

const ProtectedRoute = ({ children, requiredPermission }) => {
  const [hasPermission, setHasPermission] = useState(null); // Estado para almacenar el permiso

  useEffect(() => {
    const checkPermissions = async () => {
      const permissions = await getPermissions(); // Obtiene los permisos del token
      // Verifica si el usuario tiene el permiso requerido
      if (permissions.includes(requiredPermission) || permissions.includes("admin")) {
        setHasPermission(true); // Tiene permiso
      } else {
        setHasPermission(false); // No tiene permiso
      }
    };

    checkPermissions();
  }, [requiredPermission]); // Ejecuta cuando cambia el permiso requerido

  // Muestra un cargando mientras se verifica el permiso
  if (hasPermission === null) {
    return <div>Loading...</div>;
  }

  // Redirige si no tiene permiso
  if (!hasPermission) {
    return <Navigate to="/unauthorized" />;
  }

  // Renderiza la página si tiene permiso
  return children;
};

export default ProtectedRoute;
