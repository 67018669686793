import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import CodigoBarra from './pages/entrada/codigo-barra';
import HomePage from './pages/home/home';
import EntradaInsumos from './pages/entrada/productos';
import EntradaEquipos from './pages/entrada/equipos';
import HistorialEntrada from './pages/historial/historial-entrada';
import HistorialSalida from './pages/historial/historial-salida';
import SalidaInsumos from './pages/salida/productos';
import Reportes from './pages/reportes/reportes';
import Stocks from './pages/Stocks/Stocks';
import Login from './pages/login/login';
import AdminPage from './pages/administrador/admin';
import Unauthorized from './pages/home/error';
import ProtectedRoute from './components/protectedRoute';
import { AuthProvider } from "./components/AuthContext";
import './App.css';


function App() {
  return (
    <AuthProvider>
      {/* Mejor manera de debugear la variable de entorno */}
      {process.env.REACT_APP_API_URL ? 
        console.log("API URL está configurada:", process.env.REACT_APP_API_URL) : 
        console.log("API URL no está configurada")
      }
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/entrada-insumos" element={<ProtectedRoute requiredPermission="entrada"><EntradaInsumos /></ProtectedRoute>} />
            <Route path="/entrada-equipos" element={<ProtectedRoute requiredPermission="entrada"><EntradaEquipos /></ProtectedRoute>} />
            <Route path="/salida-productos" element={<ProtectedRoute requiredPermission="salida"><SalidaInsumos /></ProtectedRoute>} />
            <Route path="/codigo-barra" element={<ProtectedRoute requiredPermission="entrada"><CodigoBarra /></ProtectedRoute>} />
            <Route path="/stocks" element={<ProtectedRoute requiredPermission="stocks"><Stocks /></ProtectedRoute>} />
            <Route path="/historial-entrada" element={<ProtectedRoute requiredPermission="reportes"><HistorialEntrada /></ProtectedRoute>} />
            <Route path="/historial-salida" element={<ProtectedRoute requiredPermission="reportes"><HistorialSalida /></ProtectedRoute>} />
            <Route path="/reportes" element={<ProtectedRoute requiredPermission="reportes"><Reportes /></ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute requiredPermission="admin"><AdminPage /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to="/unauthorized" replace />} />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
