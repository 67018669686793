import React, { useState, useEffect, useCallback } from "react";
import CodigoBarra from "../../components/JsBar";
import api from '../../components/api.js';

const App = () => {
  const [codigos, setCodigos] = useState([]);
  const [codigoActualIndex, setCodigoActualIndex] = useState(0);

  // Obtiene la info del stock
  const loadItem = useCallback(async () => {
    try {
      const stockId = localStorage.getItem('stockId');
      const response = await api.get(`/stocks?id=${stockId}`);
      const item = response.data.items && response.data.items.length > 0 
        ? response.data.items[0] 
        : null;

      if (!item) {
        console.error('No se encontraron items en la respuesta');
        return;
      }

      const codesResponse = await api.get(`/codigos?id_producto=${stockId}`);
      setCodigos(codesResponse.data.map(item => item.codigo));
    } catch (error) {
      console.error("Error al cargar el ítem:", error);
    }
  }, []);

  // Se llama solo una vez al montar el componente
  useEffect(() => {
    loadItem();
  }, [loadItem]);

  // Genera el PDF con el código de barra para descargar
  const generarPDF = async (codigo) => {
    try {
      const response = await api.post('/generar-pdf', {
        codigo: codigo
      }, {
        responseType: 'blob'
      });
      
      // Crear URL del blob y descargar
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `codigo-${codigo}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error al generar PDF:", error);
    }
  };

  // Funciones para navegar entre códigos
  const siguienteCodigo = () => {
    if (codigoActualIndex < codigos.length - 1) {
      setCodigoActualIndex(codigoActualIndex + 1);
    }
  };

  const codigoAnterior = () => {
    if (codigoActualIndex > 0) {
      setCodigoActualIndex(codigoActualIndex - 1);
    }
  };

  // Render
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Códigos de Barras</h1>

      {codigos.length > 0 && (
        <div>
          <p>Código {codigoActualIndex + 1} de {codigos.length}</p>
          <div style={{ margin: '20px 0' }}>
            <CodigoBarra value={codigos[codigoActualIndex]} />
          </div>
          
          <div style={{ margin: '20px 0' }}>
            <button 
              onClick={codigoAnterior}
              disabled={codigoActualIndex === 0}
            >
              Anterior
            </button>
            
            <button 
              onClick={() => generarPDF(codigos[codigoActualIndex])}
              style={{ margin: '0 10px' }}
            >
              Generar PDF
            </button>
            
            <button 
              onClick={siguienteCodigo}
              disabled={codigoActualIndex === codigos.length - 1}
            >
              Siguiente
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
