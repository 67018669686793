import { jwtDecode } from "jwt-decode";
import { jwtVerify, importSPKI } from "jose";
import Swal from 'sweetalert2';

export const getPermissions = async () => {
  const payload = await verifyAndDecodeToken(); // Obtén el payload
  const permissions = payload?.permissions || []; // Asegúrate de que 'permissions' exista
  return permissions;
};

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAod07L+FROisCnaanSUri
yqp2xlMUaLkZ8Z3oTdL7S1vy4CQaw2UXZdDJHbIZAeEP0dUPuutPP4Fp+GqlASom
Stn3vWwKhz8EBG9ZyETtBQkyXenBQVPCjLSzd72ARz5R2Y1tliTgbkT4wbaBU4Yb
jYk7udvx7ZRqBglRfuKLsBMI9nhfaG3TrWlj0nvJRnhha+yiMCLogqvQlmnYh3vZ
gzH17VW+rgr5S9Aj1NwA4qfYqtCXiEbwD2g/mndooMdcT4ZVik2XbYHLlAh/njLE
WBjUDDB9bRofd8E1ULN9M7qexvYFwx8kQakO5H597OlNY17d/wSSvSWiluqKDEPI
QQIDAQAB
-----END PUBLIC KEY-----
`;

// Decodificar el token sin validarlo
export const decodeToken = () => {
  const token = localStorage.getItem("access_token");
  if (!token) return null;

  try {
    return jwtDecode(token); // Solo decodifica
  } catch (error) {
    console.error("Error al decodificar el token:", error);
    return null;
  }
};

export const verifyAndDecodeToken = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.log("No token");
    return null;
  }

  try {
    // Convierte la clave pública al formato correcto
    const key = await importSPKI(PUBLIC_KEY, "RS256");

    // Verifica el token
    const { payload } = await jwtVerify(token, key, {
      algorithms: ["RS256"],
    });

    return payload; // Retorna el payload decodificado
  } catch (error) {
    console.error("Token inválido o modificado:", error);
    localStorage.removeItem("token");
    
    Swal.fire({
      title: 'Error en la autenticación',
      text: 'Ocurrio un error en la autenticación, por favor inicie sesión nuevamente',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#3085d6'
    }).then(() => {
      window.location.href = "/login";
    });
    
    return null;
  }
};
