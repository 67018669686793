import React, { useState, useEffect, useCallback } from 'react';
import api from '../../components/api.js';
import './historial.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const HistorialEntrada = () => {
  const [productos, setProductos] = useState([]);
  const [modalFiltros, setModalFiltros] = useState(false);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [copiaFiltros, setCopiaFiltros] = useState({});
  const [filtrosTemp, setFiltrosTemp] = useState({
    nombre: '',
    precio_min: '',
    precio_max: '',
    codigo_interno: '',
    lote: '',
    ubicacion: '',
    fecha_de_ingreso_min: '',
    fecha_de_ingreso_max: '',
    limit: 10,
    pagina: 1
  });
  const [filtrosAplicados, setFiltrosAplicados] = useState({
    nombre: '',
    precio_min: '',
    precio_max: '',
    codigo_interno: '',
    lote: '',
    ubicacion: '',
    fecha_de_ingreso_min: '',
    fecha_de_ingreso_max: '',
    limit: 10,
    pagina: 1
  });
  const [itemSeleccionado, setItemSeleccionado] = useState(null);
  const [modalDetalleAbierto, setModalDetalleAbierto] = useState(false);

  // --------------------------
  // get data from backend
  // --------------------------

  const getProductos = useCallback(async (filtrosAplicar = filtrosAplicados) => {
    let extra = "";
    if (Object.keys(filtrosAplicar).length > 0) {
      extra = "?";
      for (const [key, value] of Object.entries(filtrosAplicar)) {
        if (value !== '') {
          extra += `${key}=${value}&`;
        }
      }
    }
    try {
      const response = await api.get(`/historial-entrada${extra}`);
      setProductos(response.data.items);
      setTotalPaginas(response.data.total_paginas);
    } catch (error) {
      console.error('Error al obtener productos:', error);
    }
  }, [filtrosAplicados]);

  // --------------------------
  // Filtros                   
  // --------------------------

  const handleFiltroChange = (e) => {
    const { name, value } = e.target;
    setFiltrosTemp(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLimpiarFiltros = () => {
    const filtrosLimpios = {
      nombre: '',
      precio_min: '',
      precio_max: '',
      codigo_interno: '',
      lote: '',
      ubicacion: '',
      fecha_de_ingreso_min: '',
      fecha_de_ingreso_max: '',
      limit: 10,
      pagina: 1
    };
    setFiltrosTemp(filtrosLimpios);
    setFiltrosAplicados(filtrosLimpios);
    setCopiaFiltros({});
    getProductos(filtrosLimpios);
  };

  const handlePagina = async (pagina) => {
    if (pagina > totalPaginas || pagina < 1) {
      return;
    }
    setPaginaActual(pagina);
    const nuevosFiltros = {...filtrosAplicados, pagina: pagina};
    setFiltrosAplicados(nuevosFiltros);
    setFiltrosTemp(nuevosFiltros);
    getProductos(nuevosFiltros);
  };

  const handleConfirmarFiltros = () => {
    // Validación de rangos
    if (filtrosTemp.precio_min && filtrosTemp.precio_max && 
        Number(filtrosTemp.precio_min) > Number(filtrosTemp.precio_max)) {
      alert('El precio mínimo no puede ser mayor que el máximo');
      return;
    }
    
    if (filtrosTemp.fecha_de_ingreso_min && filtrosTemp.fecha_de_ingreso_max && 
        new Date(filtrosTemp.fecha_de_ingreso_min) > new Date(filtrosTemp.fecha_de_ingreso_max)) {
      alert('La fecha inicial no puede ser posterior a la fecha final');
      return;
    }

    setFiltrosAplicados(filtrosTemp);
    setCopiaFiltros({...filtrosTemp});
    getProductos(filtrosTemp);
    setModalFiltros(false);
  };

  const cancelarFiltros = () => {
    setFiltrosTemp({...copiaFiltros});
    setModalFiltros(false);
  };

  const handleAbrirFiltros = () => {
    setCopiaFiltros(filtrosTemp);
    setModalFiltros(true);
  };

  const handleClickItem = (producto) => {
    setItemSeleccionado(producto);
    setModalDetalleAbierto(true);
  };

  const handleCerrarModalDetalle = () => {
    setModalDetalleAbierto(false);
    setItemSeleccionado(null);
  };

  // --------------------------
  // Main
  // --------------------------

  useEffect(() => {
    getProductos();
  }, [getProductos]);

  return (
    <>
      <div className="contenedor-boton-filtros">
        <button 
          className="boton-filtros"
          onClick={handleAbrirFiltros}
        >
          Filtros
        </button>
      </div>
      {modalFiltros && (
        <div className="modal-overlay">
          <div className="modal modal-filtros">
            <h2>Filtros de Búsqueda</h2>
            <div className="filtros-grid">
              <div className="form-group">
                <label>Nombre:</label>
                <input
                  type="text"
                  name="nombre"
                  value={filtrosTemp.nombre}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por nombre"
                />
              </div>

              <div className="form-group">
                <label>Rango de Precio:</label>
                <div className="rango-inputs">
                  <input
                    type="number"
                    name="precio_min"
                    value={filtrosTemp.precio_min}
                    onChange={handleFiltroChange}
                    placeholder="Mínimo"
                  />
                  <input
                    type="number"
                    name="precio_max"
                    value={filtrosTemp.precio_max}
                    onChange={handleFiltroChange}
                    placeholder="Máximo"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Código:</label>
                <input
                  type="text"
                  name="codigo_interno"
                  value={filtrosTemp.codigo_interno}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por código"
                />
              </div>

              <div className="form-group">
                <label>Lote:</label>
                <input
                  type="text"
                  name="lote"
                  value={filtrosTemp.lote}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por lote"
                />
              </div>

              <div className="form-group">
                <label>Ubicación:</label>
                <input
                  type="text"
                  name="ubicacion"
                  value={filtrosTemp.ubicacion}
                  onChange={handleFiltroChange}
                  placeholder="Buscar por ubicación"
                />
              </div>

              <div className="form-group">
                <label>Rango de Fecha de Ingreso:</label>
                <div className="rango-inputs">
                  <input
                    type="date"
                    name="fecha_de_ingreso_min"
                    value={filtrosTemp.fecha_de_ingreso_min}
                    onChange={handleFiltroChange}
                  />
                  <input
                    type="date"
                    name="fecha_de_ingreso_max"
                    value={filtrosTemp.fecha_de_ingreso_max}
                    onChange={handleFiltroChange}
                  />
                </div>
              </div>
            </div>

            <div className="modal-buttons">
              <button 
                type="button" 
                className="boton-limpiar-filtros"
                onClick={handleLimpiarFiltros}
              >
                Limpiar Filtros
              </button>
              <button 
                type="button" 
                className="boton-aplicar-filtros"
                onClick={handleConfirmarFiltros}
              >
                Aplicar Filtros
              </button>
              <button 
                type="button" 
                className="boton-cancelar-filtros"
                onClick={cancelarFiltros}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )} 

      <TableContainer component={Paper} className="tabla-container">
        <Table aria-label="tabla de historial de entrada">
          <TableHead>
            <TableRow className="tabla-header">
              <TableCell>Nombre</TableCell>
              <TableCell align="right">Cantidad</TableCell>
              <TableCell>Clasificación</TableCell>
              <TableCell>Ubicación</TableCell>
              <TableCell>Fecha de Ingreso</TableCell>
              <TableCell>Proveedor</TableCell>
              <TableCell align="right">Precio Individual</TableCell>
              <TableCell align="right">IVA</TableCell>
              <TableCell align="right">Precio Total</TableCell>
              <TableCell>Moneda</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.length > 0 ? (
              productos.map((row, index) => (
                <TableRow 
                  key={index} 
                  className="tabla-row"
                  onClick={() => handleClickItem(row)}
                >
                  <TableCell className="tabla-cell">{row.nombre}</TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    {row.cantidad?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell">{row.clasificacion}</TableCell>
                  <TableCell className="tabla-cell">{row.ubicacion}</TableCell>
                  <TableCell className="tabla-cell">{row.fecha_de_ingreso}</TableCell>
                  <TableCell className="tabla-cell">{row.nombre_prov}</TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    {row.precio?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    {row.IVA?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell tabla-cell-numeric" align="right">
                    {row.precio_total?.toLocaleString('es-CL')}
                  </TableCell>
                  <TableCell className="tabla-cell">{row.moneda}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} className="mensaje-sin-datos">
                  No hay datos disponibles.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination-container">
        <button className="pagination-button"
        onClick={() => paginaActual > 1 ? handlePagina(paginaActual - 1) : null}>
          ←
        </button>
        <div className="pagination-number">{paginaActual}</div>
        <button className="pagination-button"
        onClick={() => handlePagina(paginaActual + 1)}>
          →
        </button>
      </div>

      {modalDetalleAbierto && itemSeleccionado && (
        <div className="modal-overlay">
          <div className="modal modal-detalles modal-grande">
            <h2>Detalles del Producto</h2>
            <div className="detalles-item">
              <div className="detalles-grid">
                <div className="detalle-grupo">
                  <p><strong>Código:</strong> {itemSeleccionado.codigo}</p>
                  <p><strong>Nombre:</strong> {itemSeleccionado.nombre}</p>
                  <p><strong>Precio:</strong> ${itemSeleccionado.precio?.toLocaleString('es-CL')}</p>
                  <p><strong>Cantidad:</strong> {itemSeleccionado.cantidad}</p>
                  <p><strong>IVA:</strong> ${itemSeleccionado.IVA?.toLocaleString('es-CL')}</p>
                  <p><strong>Precio Total:</strong> ${itemSeleccionado.precio_total?.toLocaleString('es-CL')}</p>
                </div>

                <div className="detalle-grupo">
                  <p><strong>Lote:</strong> {itemSeleccionado.lote}</p>
                  <p><strong>Unidad/Formato:</strong> {itemSeleccionado.u_formato}</p>
                  <p><strong>N° CAS:</strong> {itemSeleccionado.n_cas}</p>
                  <p><strong>Procedencia:</strong> {itemSeleccionado.procedencia}</p>
                  <p><strong>Clasificación:</strong> {itemSeleccionado.clasificacion}</p>
                  <p><strong>Centro de Costos:</strong> {itemSeleccionado.centro_de_costos}</p>
                </div>

                <div className="detalle-grupo">
                  <p><strong>Ubicación:</strong> {itemSeleccionado.ubicacion}</p>
                  <p><strong>Fecha de Ingreso:</strong> {itemSeleccionado.fecha_de_ingreso}</p>
                  <p><strong>Fecha de Vencimiento:</strong> {itemSeleccionado.fecha_de_vencimiento}</p>
                  <p><strong>Fecha de Fabricación:</strong> {itemSeleccionado.fecha_de_fabricacion}</p>
                  <p><strong>Fecha de Factura:</strong> {itemSeleccionado.fecha_de_factura}</p>
                  <p><strong>N° Factura:</strong> {itemSeleccionado.n_factura}</p>
                  <p><strong>Sede:</strong> {itemSeleccionado.sede}</p>
                </div>

                <div className="descripcion-seccion">
                  <p><strong>Descripción:</strong></p>
                  <p>{itemSeleccionado.descripcion}</p>
                </div>
              </div>
            </div>
            <div className="modal-buttons">
              <button 
                type="button" 
                className="boton-cerrar"
                onClick={handleCerrarModalDetalle}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HistorialEntrada;