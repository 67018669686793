import React from "react";

const Unauthorized = () => {
  return (
    <div>
      <h1>Error 403: No tienes permiso para acceder a esta página</h1>
      <p>Por favor, contacta al administrador si necesitas acceso.</p>
    </div>
  );
};

export default Unauthorized;