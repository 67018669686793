import React, { createContext, useEffect } from "react";
import api from './api.js';

const AuthContext = createContext();

const RENEWAL_INTERVAL = 14 * 60 * 1000;
const INACTIVITY_TIMEOUT = 15 * 60 * 1000;
let inactivityTimer;

const renovarToken = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return;

    const response = await api.post('/renovar-token', { token });
    localStorage.setItem("token", response.data.token);
  } catch (error) {
    console.error("Error al renovar el token:", error);
    cerrarSesion();
  }
};

const cerrarSesion = () => {
  console.log("Sesión cerrada por inactividad");
  localStorage.removeItem("token");
  window.location.href = "/login";
};

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    cerrarSesion();
  }, INACTIVITY_TIMEOUT);
};

const iniciarMonitoreo = () => {
  ["click", "keypress"].forEach((event) => {
    window.addEventListener(event, resetInactivityTimer);
  });

  setInterval(() => {
    renovarToken();
  }, RENEWAL_INTERVAL);

  resetInactivityTimer();
};

export const AuthProvider = ({ children }) => {
  useEffect(() => {
    iniciarMonitoreo();
  }, []);

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};
