import axios from "axios";
import Swal from 'sweetalert2';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://127.0.0.1:8000", // URL del backend
  headers: {},
});

// Interceptor de peticiones: agrega el token dinámicamente en cada request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor de respuestas: maneja la invalidación del token globalmente
api.interceptors.response.use(
  (response) => response, // Si la respuesta es correcta, la retorna sin cambios
  (error) => {
    if (error.response && error.response.data.detail === "Token inválido") {
      // Eliminar el token almacenado
      localStorage.removeItem("token");

      // Mostrar alerta estilizada y redirigir al usuario al login
      Swal.fire({
        title: 'Error en la autenticación',
        text: 'Se inicio otra sesión en otro dispositivo, si no es tu sesión, por favor contacta al administrador',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          // Prevenir la redirección automática
          window.onbeforeunload = () => true;
        },
        didClose: () => {
          // Remover el bloqueo de redirección
          window.onbeforeunload = null;
        }
      }).then(() => {
        window.location.href = "/login";
      });

      return Promise.reject(error); // Rechaza el error para detener la ejecución
    }
    return Promise.reject(error);
  }
);

export default api; // Exportar la instancia configurada
