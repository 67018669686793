import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api.js';
import { Container, Paper, Grid, TextField, Button, Typography, Stepper, Step, StepLabel, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import './Insumos.css';

const ProductosForm = () => {
  // --------------------------
  // FUNCIONES AUXILIARES
  // --------------------------
  const navigate = useNavigate();
  const parseNumericValue = useCallback((value) => {
    if (!value && value !== 0) return 0;
    const cleanValue = value.toString().replace(/\./g, '').replace(',', '.');
    return parseFloat(cleanValue) || 0;
  }, []);
  const formatNumberToCL = useCallback((num) => 
    num
      ? Math.round(num).toLocaleString('es-CL')
      : ''
  , []);

  // --------------------------
  // ESTADOS
  // --------------------------
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    nombre: '',
    precio: '',
    cantidad: '',
    n_cas: '',
    codigo: '',
    descripcion: '',
    lote: null,
    u_formato: 'N/A',
    procedencia: '',
    clasificacion: 'Equipos',
    fecha_de_ingreso: new Date().toISOString().split('T')[0],
    fecha_de_vencimiento: null,
    fecha_de_fabricacion: '',
    ubicacion: '',
    nombre_prov: '',
    rut: '',
    giro: '',
    direccion: '',
    fecha_de_factura: '',
    n_factura: '',
    IVA: '',
    precio_total: '',
    contacto: '',
    pais: '',
    ciudad: '',
    telefono: '',
    nombre_contacto: '',
    sede: '',
    centro_de_costos: '',
    moneda: 'CLP',
  });
  const [errors, setErrors] = useState({});

  // Manejo de Proveedor:
  const [showNewProvider, setShowNewProvider] = useState(false);
  const [rutError, setRutError] = useState('');
  const [proveedorName, setProveedorName] = useState([]);
  const [proveedorSugerencias, setProveedorSugerencias] = useState([]);

  // Manejo de Sede:
  const [sedes, setSedes] = useState([]);
  const [showNewSede, setShowNewSede] = useState(false);
  const [nuevaSede, setNuevaSede] = useState('');

  // Manejo de Centro de Costos:
  const [centrosDeCostos, setCentrosDeCosto] = useState([]);
  const [showNewCentroCosto, setShowNewCentroCosto] = useState(false);
  const [nuevoCentroCosto, setNuevoCentroCosto] = useState('');

  const [loading, setLoading] = useState(true);

  // Agregar nuevo estado para el checkbox
  const [isExtranjero, setIsExtranjero] = useState(false);

  // --------------------------
  // EFECTOS
  // --------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getSedes(), getCentrosDeCosto(), getProveedores()]);
      } catch (error) {
        console.error("Error cargando datos", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const { precio, cantidad } = formData;
    if (precio || cantidad) {
      try {
        const precioLimpio = parseNumericValue(precio);
        const cantidadLimpia = parseNumericValue(cantidad);

        const precioBase = precioLimpio * cantidadLimpia;
        const iva = Math.round(precioBase * 0.19);
        const precioTotal = precioBase + iva;

        setFormData(prev => ({
          ...prev,
          IVA: formatNumberToCL(iva),
          precio_total: formatNumberToCL(precioTotal)
        }));
      } catch (error) {
        console.error('Error en el cálculo:', error);
      }
    }
  }, [formData, parseNumericValue, formatNumberToCL]);

  // --------------------------
  // OBTENER DATOS (GET)
  // --------------------------
  const getProveedores = async () => {
    try {
      const response = await api.get('/proveedores');
      setProveedorName(response.data.map((p) => [p.nombre_prov, p.id]));
    } catch (err) {
      console.error('Error al obtener proveedores:', err);
    }
  };

  const getSedes = async () => {
    try {
      const response = await api.get('/sedes');
      setSedes(response.data);
    } catch (err) {
      console.error('Error al obtener sedes:', err);
    }
  };

  const getCentrosDeCosto = async () => {
    try {
      const response = await api.get('/centrosdecosto');
      setCentrosDeCosto(response.data);
    } catch (err) {
      console.error('Error al obtener centros de costo:', err);
    }
  };

  // --------------------------
  // MANEJADORES GENERALES
  // --------------------------
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'isExtranjero') {
      setIsExtranjero(checked);
      setFormData(prev => ({
        ...prev,
        rut: checked ? 'extranjero' : ''
      }));
      return;
    }

    // Manejo específico para RUT
    if (name === 'rut') {
      if (isExtranjero) return; // No permitir cambios si es extranjero
      
      // Elimina todos los caracteres no numéricos y guiones
      const cleanValue = value.replace(/[^\d-]/g, '');
      // Elimina todos los guiones para trabajar solo con números
      const numbers = cleanValue.replace(/-/g, '');
      
      if (numbers.length > 1) {
        // Inserta el guión antes del último dígito
        const withDash = numbers.slice(0, -1) + '-' + numbers.slice(-1);
        setFormData(prev => ({ ...prev, rut: withDash }));
      } else {
        // Si hay 1 o menos dígitos, solo mantener los números
        setFormData(prev => ({ ...prev, rut: numbers }));
      }
      return;
    }

    // Manejo específico para precio y cantidad con formateo a miles
    if (name === 'precio' || name === 'cantidad') {
      const cleanValue = value.replace(/[^\d.]/g, ''); // quita todo excepto dígitos y punto
      const numValue = parseNumericValue(cleanValue);

      if (!isNaN(numValue)) {
        const formattedValue = numValue.toLocaleString('es-CL');
        if (name === 'cantidad') {
          const cantidad = Math.max(1, numValue);
          setFormData((prev) => ({ ...prev, cantidad: cantidad.toLocaleString('es-CL') }));
        } else {
          setFormData((prev) => ({ ...prev, [name]: formattedValue }));
        }
      }
      return;
    }

    // Resto de los campos
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNext = () => {
    if (validateFirstStep()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
    if (formData.n_cas === '') {
      setFormData((prev) => ({ ...prev, n_cas: '0' }));
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // --------------------------
  // MANEJO DE PROVEEDOR
  // --------------------------
  const handleProvName = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({ ...prev, nombre_prov: value }));

    if (value.length > 0) {
      const sugerencias = proveedorName.filter((nombre) =>
        nombre[0].toLowerCase().includes(value.toLowerCase())
      );
      setProveedorSugerencias(sugerencias);
    } else {
      setProveedorSugerencias([]);
    }
  };

  const handleProveedorSelect = async (id) => {
    try {
      const response = await api.get(`/proveedores?id=${id}`);
      const data = response.data[0];
      setFormData((prev) => ({
        ...prev,
        nombre_prov: data.nombre_prov,
        rut: data.rut,
        giro: data.giro,
        direccion: data.direccion,
        contacto: data.contacto,
        pais: data.pais,
        ciudad: data.ciudad,
        nombre_contacto: data.nombre_de_contacto,
        telefono: data.telefono,
      }));
      setProveedorSugerencias([]);
    } catch (err) {
      console.error('Error al seleccionar proveedor:', err);
    }
  };

  const handleRutBlur = async () => {
    if (formData.rut && !isExtranjero) {
      try {
        const response = await api.get(`/proveedores?rut=${formData.rut}`);
        if (response.data && response.data.length > 0) {
          const prov = response.data[0];
          setFormData((prev) => ({
            ...prev,
            nombre_prov: prov.nombre_prov,
            giro: prov.giro,
            direccion: prov.direccion,
            contacto: prov.contacto,
            pais: prov.pais,
            ciudad: prov.ciudad,
            nombre_contacto: prov.nombre_de_contacto,
            telefono: prov.telefono,
          }));
        }
      } catch (error) {
        console.error('Error al buscar proveedor:', error);
      }
    }
  };

  // --------------------------
  // GUARDAR PRODUCTO
  // --------------------------
  const handleGuardar = async () => {
    if (!validateFirstStep()) return;
    setLoading(true);
    if (formData.rut === '') {
      setRutError('El RUT es obligatorio');
      setLoading(false);
      return;
    }
    if (showNewProvider) {
      if (
        formData.nombre_prov !== '' &&
        formData.rut !== '' &&
        formData.giro !== '' &&
        formData.direccion !== '' &&
        formData.contacto !== '' &&
        formData.pais !== '' &&
        formData.ciudad !== '' &&
        formData.nombre_contacto !== '' &&
        formData.telefono !== ''
      ) {
        try {
          const response = await api.get(`/proveedores?rut=${formData.rut}`);
          if (formData.rut === 'extranjero' || response.data.length === 0) {
            const response2 = await api.post('/proveedores', {
              nombre_prov: formData.nombre_prov,
              rut: formData.rut,
              giro: formData.giro,
              direccion: formData.direccion,
              contacto: formData.contacto,
              pais: formData.pais,
              ciudad: formData.ciudad,
              nombre_de_contacto: formData.nombre_contacto,
              telefono: formData.telefono,
            });
            await handleSaveProducto(response2.data.id);
            setRutError('');
            await navigate('/codigo-barra');
            window.location.reload();
          } else {
            setRutError('Rut ya registrado');
          }
        } catch (error) {
          setRutError('Error al verificar/crear el proveedor');
        } finally {
          setLoading(false);
        }
      } else {
        setRutError('Todos los campos son obligatorios');
        setLoading(false);
      }
    } else {
      try {
        const response = await api.get(`/proveedores?rut=${formData.rut}`);
        if (response.data.length === 0) {
          setRutError('El proveedor no existe. Por favor, registre un nuevo proveedor.');
        } else {
          // Proveedor ya existe
          const prov = response.data[0];
          // Actualizar formData con datos del proveedor
          const updatedFormData = {
            ...formData,
            nombre_prov: prov.nombre_prov,
            giro: prov.giro,
            direccion: prov.direccion,
            contacto: prov.contacto,
            pais: prov.pais,
            ciudad: prov.ciudad,
            nombre_contacto: prov.nombre_de_contacto,
            telefono: prov.telefono,
          };
          setFormData(updatedFormData);

          await handleSaveProducto(prov.id);
          await navigate('/codigo-barra');
          window.location.reload();
        }
      } catch (error) {
        setRutError('Error al verificar el proveedor');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveProducto = async (idProveedor) => {
    try {
      const proveedorResponse = idProveedor
        ? { data: [{ id: idProveedor }] }
        : await api.get(`/proveedores?rut=${formData.rut}`);

      const updatedData = {
        ...formData,
        rut: parseInt(formData.rut.replace(/-/g, ''), 10),
        precio: parseInt(formData.precio.replace(/\./g, ''), 10) || 0,
        cantidad: parseInt(formData.cantidad.replace(/\./g, ''), 10) || 1,
        IVA: parseInt(formData.IVA.replace(/\./g, ''), 10) || 0,
        precio_total: parseInt(formData.precio_total.replace(/\./g, ''), 10) || 0,
        id_proveedor: proveedorResponse.data[0].id,
      };

      const historialResponse = await api.post('/entradasProductos', updatedData);
      console.log('Historial guardado exitosamente', historialResponse);

      const stockResponse = await api.post('/stocks', {...updatedData, id_entrada: historialResponse.data.id});
      console.log('Stock guardado exitosamente', stockResponse);

      localStorage.setItem('stockId', stockResponse.data.id);
    } catch (error) {
      console.error('Error al guardar el producto:', error);
    }
  };

  // --------------------------
  // VALIDACIÓN
  // --------------------------
  const validateFirstStep = () => {
    const newErrors = {};
    const requiredFields = [
      { name: 'nombre', label: 'Nombre' },
      { name: 'precio', label: 'Precio' },
      { name: 'cantidad', label: 'Cantidad' },
      { name: 'codigo', label: 'Código' },
      { name: 'procedencia', label: 'Procedencia' },
      { name: 'fecha_de_ingreso', label: 'Fecha de Ingreso' },
      { name: 'fecha_de_fabricacion', label: 'Fecha de Fabricación' },
      { name: 'ubicacion', label: 'Ubicación' },
      { name: 'fecha_de_factura', label: 'Fecha de Factura' },
      { name: 'n_factura', label: 'Número de Factura' },
      { name: 'IVA', label: 'IVA' },
      { name: 'precio_total', label: 'Precio Total' },
      { name: 'sede', label: 'Sede' },
      { name: 'centro_de_costos', label: 'Centro de Costos' },
    ];

    requiredFields.forEach((field) => {
      if (!formData[field.name] || formData[field.name].toString().trim() === '') {
        newErrors[field.name] = `${field.label} es obligatorio`;
      }
    });

    // Validar campos numéricos solo si existen
    if (formData.precio) {
      const precio = parseNumericValue(formData.precio);
      if (precio < 0) {
        newErrors.precio = 'El precio no puede ser negativo';
      }
    }

    if (formData.cantidad) {
      const cantidad = parseNumericValue(formData.cantidad);
      if (!cantidad || cantidad < 1) {
        newErrors.cantidad = 'La cantidad debe ser al menos 1';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // --------------------------
  // NUEVA SEDE, CENTRO, CLASIFICACIÓN
  // (funciones muy similares)
  // --------------------------
  const handleGuardarSede = async () => {
    if (!nuevaSede.trim()) return;
    try {
      setShowNewSede(false);
      const checkResponse = await api.get(`/sedes?nombre=${nuevaSede}`);
      if (checkResponse.data.length === 0) {
        await api.post('/sedes', { nombre: nuevaSede });
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      await getSedes();
    } catch (error) {
      console.log('Error al crear la sede', error);
    }
    setNuevaSede('');
  };

  const handleGuardarCentroCosto = async () => {
    if (!nuevoCentroCosto.trim()) return;
    try {
      setShowNewCentroCosto(false);
      const checkResponse = await api.get(`/centrosdecosto?nombre=${nuevoCentroCosto}`);
      if (checkResponse.data.length === 0) {
        await api.post('/centrosdecosto', { nombre: nuevoCentroCosto });
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      await getCentrosDeCosto();
    } catch (error) {
      console.log('Error al crear el centro de costo', error);
    }
    setNuevoCentroCosto('');
  };

  // --------------------------
  // RENDER
  // --------------------------
  if (loading) return <p>Cargando...</p>;
  return (
    <Container>
      {/* Escenario: NO hay error y el producto se guarda exitosamente */}
      {/* Formulario (2 pasos) */}
      <Paper elevation={3} className="form-container">
        <Stepper activeStep={activeStep} className="form-stepper">
              <Step>
                <StepLabel>Datos del Producto</StepLabel>
              </Step>
              <Step>
                <StepLabel>Datos del Proveedor</StepLabel>
              </Step>
            </Stepper>

            <form onSubmit={handleSubmit}>
              {/* Paso 1: Datos del Producto */}
              {activeStep === 0 ? (
                <>
                  <Typography variant="h5" gutterBottom className="form-title">
                    Ingreso de Equipo
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        error={!!errors.nombre}
                        helperText={errors.nombre}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Cantidad"
                        name="cantidad"
                        type="text"
                        value={formData.cantidad}
                        onChange={handleChange}
                        error={!!errors.cantidad}
                        helperText={errors.cantidad}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} container spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          required
                          fullWidth
                          label="Precio Individual"
                          name="precio"
                          type="text"
                          value={formData.precio}
                          onChange={handleChange}
                          error={!!errors.precio}
                          helperText={errors.precio}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          select
                          fullWidth
                          label="Moneda"
                          name="moneda"
                          value={formData.moneda}
                          onChange={handleChange}
                        >
                          <MenuItem value="CLP">CLP</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="IVA"
                        name="IVA"
                        type="text"
                        value={formData.IVA}
                        onChange={handleChange}
                        error={!!errors.IVA}
                        helperText={errors.IVA}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Precio Total"
                        name="precio_total"
                        type="text"
                        value={formData.precio_total}
                        onChange={handleChange}
                        error={!!errors.precio_total}
                        helperText={errors.precio_total}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Número de Factura"
                        name="n_factura"
                        value={formData.n_factura}
                        onChange={handleChange}
                        error={!!errors.n_factura}
                        helperText={errors.n_factura}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Fecha de Factura"
                        name="fecha_de_factura"
                        type="date"
                        value={formData.fecha_de_factura}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.fecha_de_factura}
                        helperText={errors.fecha_de_factura}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Número de serie"
                        name="n_cas"
                        value={formData.n_cas}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Clasificación"
                        name="clasificacion"
                        value="Equipos"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Código"
                        name="codigo"
                        value={formData.codigo}
                        onChange={handleChange}
                        error={!!errors.codigo}
                        helperText={errors.codigo}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Ubicación"
                        name="ubicacion"
                        value={formData.ubicacion}
                        onChange={handleChange}
                        error={!!errors.ubicacion}
                        helperText={errors.ubicacion}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Procedencia"
                        name="procedencia"
                        value={formData.procedencia}
                        onChange={handleChange}
                        error={!!errors.procedencia}
                        helperText={errors.procedencia}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Fecha de Ingreso"
                        name="fecha_de_ingreso"
                        type="date"
                        value={formData.fecha_de_ingreso}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.fecha_de_ingreso}
                        helperText={errors.fecha_de_ingreso}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Fecha de Fabricación"
                        name="fecha_de_fabricacion"
                        type="date"
                        value={formData.fecha_de_fabricacion}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.fecha_de_fabricacion}
                        helperText={errors.fecha_de_fabricacion}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Descripción"
                        name="descripcion"
                        multiline
                        rows={4}
                        value={formData.descripcion}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        label="Centro de Costos"
                        name="centro_de_costos"
                        value={formData.centro_de_costos}
                        onChange={handleChange}
                        error={!!errors.centro_de_costos}
                        helperText={errors.centro_de_costos}
                        InputProps={{
                          endAdornment: (
                            <Button onClick={() => setShowNewCentroCosto(true)} sx={{ minWidth: '40px', p: 0 }}>
                              +
                            </Button>
                          ),
                        }}
                      >
                        {centrosDeCostos.map((centro) => (
                          <MenuItem key={centro.id} value={centro.nombre}>
                            {centro.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        label="Sede"
                        name="sede"
                        value={formData.sede}
                        onChange={handleChange}
                        error={!!errors.sede}
                        helperText={errors.sede}
                        InputProps={{
                          endAdornment: (
                            <Button onClick={() => setShowNewSede(true)} sx={{ minWidth: '40px', p: 0 }}>
                              +
                            </Button>
                          ),
                        }}
                      >
                        {sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.nombre}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleNext}
                      >
                        Siguiente
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                // Paso 2: Datos del Proveedor
                <>
                  <Typography variant="h5" gutterBottom className="form-title">
                    Datos del Proveedor
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="RUT"
                        name="rut"
                        value={formData.rut}
                        onChange={handleChange}
                        onBlur={handleRutBlur}
                        error={!!errors.rut}
                        helperText={errors.rut || rutError}
                        InputProps={{
                          readOnly: isExtranjero
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isExtranjero}
                            onChange={handleChange}
                            name="isExtranjero"
                          />
                        }
                        label="Proveedor Extranjero"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="proveedor-container">
                      <TextField
                        required
                        fullWidth
                        label="Nombre del Proveedor"
                        name="nombre_prov"
                        value={formData.nombre_prov}
                        onChange={handleProvName}
                        error={!!errors.nombre_prov}
                        helperText={errors.nombre_prov}
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() => setShowNewProvider(!showNewProvider)}
                              sx={{ minWidth: '40px', p: 0 }}
                            >
                              {showNewProvider ? '-' : '+'}
                            </Button>
                          ),
                        }}
                      />
                      {proveedorSugerencias.length > 0 && (
                        <div className="proveedor-sugerencias">
                          {proveedorSugerencias.map((sugerencia, index) => (
                            <div
                              key={index}
                              onClick={() => handleProveedorSelect(sugerencia[1])}
                              className="sugerencia-item"
                            >
                              {sugerencia[0]}
                            </div>
                          ))}
                        </div>
                      )}
                    </Grid>

                    {showNewProvider && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            label="Giro"
                            name="giro"
                            value={formData.giro}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Dirección"
                            name="direccion"
                            value={formData.direccion}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Correo del contacto"
                            name="contacto"
                            value={formData.contacto}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            label="País"
                            name="pais"
                            value={formData.pais}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            label="Ciudad"
                            name="ciudad"
                            value={formData.ciudad}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            label="Nombre del Contacto"
                            name="nombre_contacto"
                            value={formData.nombre_contacto}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            label="Teléfono de Contacto"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <div className="button-container">
                    <Button variant="outlined" onClick={handleBack} className="secondary-button">
                      Atrás
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleGuardar}
                    >
                      Guardar
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Paper>

      {/* Dialog para agregar NUEVA SEDE */}
      <Dialog open={showNewSede} onClose={() => setShowNewSede(false)}>
        <DialogTitle>Agregar Nueva Sede</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Sede"
            fullWidth
            value={nuevaSede}
            onChange={(e) => setNuevaSede(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={() => setShowNewSede(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleGuardarSede}
            color="primary"
            variant="contained"
            disabled={!nuevaSede.trim()}
          >
            Guardar Sede
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para agregar NUEVO CENTRO DE COSTO */}
      <Dialog open={showNewCentroCosto} onClose={() => setShowNewCentroCosto(false)}>
        <DialogTitle>Agregar Nuevo Centro de Costo</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Centro de Costo"
            fullWidth
            value={nuevoCentroCosto}
            onChange={(e) => setNuevoCentroCosto(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={() => setShowNewCentroCosto(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleGuardarCentroCosto}
            color="primary"
            variant="contained"
            disabled={!nuevoCentroCosto.trim()}
          >
            Guardar Centro de Costo
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProductosForm;
