import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api.js';
import { getPermissions } from '../../components/utils';
import {
  Container,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import './Insumos.css';

const SalidaInsumos = () => {
  const navigate = useNavigate();
  
  // Agregar estados para el diálogo
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogActions, setDialogActions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  // Agregar nuevo estado para el checkbox
  const [isExtranjero, setIsExtranjero] = useState(false);

  // Agregar estado para error de RUT
  const [rutError, setRutError] = useState('');

  // --------------------------
  // FUNCIONES AUXILIARES
  // --------------------------
  const getIsAdmin = async () => {
    const permissions = await getPermissions();
    setIsAdmin(permissions.includes('admin'));
  };

  const parseNumericValue = (value) => // my flaws burn through my skin like demonic flames from hell
    parseFloat(value.toString().replace(/\./g, '').replace(',', '.')) || 0; // ...and to those I love, thanks for sticking around.

  const formatNumberToCL = (num) =>
    num
      ? Math.round(num).toLocaleString('es-CL') // just search them in spotify
      : ''; // a little easter egg for you ;)
    
  
  // --------------------------
  // SEDES
  // --------------------------
  const [nuevaSede, setNuevaSede] = useState('');
  const [showNewSede, setShowNewSede] = useState(false);
  const [sedes, setSedes] = useState([]);

  const handleGuardarSede = async () => {
    if (!nuevaSede.trim()) return;
    try {
      setShowNewSede(false);
      const checkResponse = await api.get(`/sedes?nombre=${nuevaSede}`);
      if (checkResponse.data.length === 0) {
        await api.post('/sedes', { nombre: nuevaSede });
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      await getSedes();
    } catch (error) {
      console.log('Error al crear la sede', error);
    }
    setNuevaSede('');
  };

  const getSedes = async () => {
    try {
      const response = await api.get('/sedes');
      setSedes(response.data);
    } catch (err) {
      console.error('Error al obtener sedes:', err);
    }
  };
  // --------------------------
  // CENTROS DE COSTO
  // --------------------------
  const [nuevoCentroCosto, setNuevoCentroCosto] = useState('');
  const [showNewCentroCosto, setShowNewCentroCosto] = useState(false);
  const [centrosDeCostos, setCentrosDeCostos] = useState([]);

  const handleGuardarCentroCosto = async () => {
    if (!nuevoCentroCosto.trim()) return;
    try {
      setShowNewCentroCosto(false);
      const checkResponse = await api.get(`/centrosdecosto?nombre=${nuevoCentroCosto}`);
      if (checkResponse.data.length === 0) {
        await api.post('/centrosdecosto', { nombre: nuevoCentroCosto });
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      await getCentrosDeCosto();
    } catch (error) {
      console.log('Error al crear el centro de costo', error);
    }
    setNuevoCentroCosto('');
  };

  const getCentrosDeCosto = async () => {
    try {
      const response = await api.get('/centrosdecosto');
      setCentrosDeCostos(response.data);
    } catch (err) {
      console.error('Error al obtener centros de costo:', err);
    }
  };

  // --------------------------
  // ESTADOS
  // --------------------------
  const [activeStep, setActiveStep] = useState(0);

  // Manejo de formulario:
  const [formData, setFormData] = useState({
    precio: '',
    cantidad: '',
    codigo: '',
    descripcion: '',
    fecha_de_salida: new Date().toISOString().split('T')[0],
    nombre_cliente: '',
    rut: '',
    giro: '',
    direccion: '',
    fecha_de_nota_venta: '',
    IVA: '',
    precio_total: '',
    contacto: '',
    pais: '',
    ciudad: '',
    telefono: '',
    nombre_de_contacto: '',
    moneda: 'CLP',
    sede: '',
    centro_de_costos: ''
  });
  const [errors, setErrors] = useState({});

  // Manejo de Cliente:
  const [showNewCliente, setShowNewCliente] = useState(false);
  const [clienteName, setClienteName] = useState([]);
  const [clienteSugerencias, setClienteSugerencias] = useState([]);

  // Agregar nuevo estado para productos múltiples
  const [productos, setProductos] = useState([{ codigo: '', precio: '' }]);
  const [cantidadProductos, setCantidadProductos] = useState(1);

  // --------------------------
  // EFECTOS
  // --------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getClientes(), getSedes(), getCentrosDeCosto(), getIsAdmin()]);
      } catch (error) {
        console.error("Error cargando datos", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Recalcular IVA y total cuando cambien los productos
  useEffect(() => {
    try {
      const precioBase = productos.reduce((total, producto) => {
        const precio = parseNumericValue(producto.precio);
        return total + precio;
      }, 0);

      const iva = precioBase * 0.19; // IVA 19%
      const precioTotal = precioBase + iva;

      setFormData((prev) => ({
        ...prev,
        IVA: formatNumberToCL(iva),
        precio_total: formatNumberToCL(precioTotal),
      }));
    } catch (error) {
      console.error('Error en el cálculo:', error);
    }
  }, [productos]);

  // --------------------------
  // OBTENER DATOS (GET)
  // --------------------------
  const getClientes = async () => {
    try {
      const response = await api.get('/clientes');
      setClienteName(response.data.map((p) => [p.nombre_cliente, p.id]));
    } catch (err) {
      console.error('Error al obtener clientes:', err);
    }
  };

  // --------------------------
  // MANEJADORES GENERALES
  // --------------------------
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'isExtranjero') {
      setIsExtranjero(checked);
      setFormData(prev => ({
        ...prev,
        rut: checked ? 'extranjero' : ''
      }));
      return;
    }

    // Manejo específico para precio y cantidad con formateo a miles
    if (name === 'precio' || name === 'cantidad') {
      const cleanValue = value.replace(/[^\d.]/g, ''); // quita todo excepto dígitos y punto
      const numValue = parseNumericValue(cleanValue);

      if (!isNaN(numValue)) {
        const formattedValue = numValue.toLocaleString('es-CL');
        if (name === 'cantidad') {
          const cantidad = Math.max(1, numValue); // la cantidad mínima es 1
          setFormData((prev) => ({
            ...prev,
            cantidad: cantidad.toLocaleString('es-CL'),
          }));
        } else {
          setFormData((prev) => ({ ...prev, [name]: formattedValue }));
        }
      }
      return;
    }

    // Manejo específico para RUT
    if (name === 'rut') {
      if (isExtranjero) return; // No permitir cambios si es extranjero
      
      // Elimina todos los caracteres no numéricos y guiones
      const cleanValue = value.replace(/[^\d-]/g, '');
      // Elimina todos los guiones para trabajar solo con números
      const numbers = cleanValue.replace(/-/g, '');
      
      if (numbers.length > 1) {
        // Inserta el guión antes del último dígito
        const withDash = numbers.slice(0, -1) + '-' + numbers.slice(-1);
        setFormData(prev => ({ ...prev, rut: withDash }));
      } else {
        // Si hay 1 o menos dígitos, solo mantener los números
        setFormData(prev => ({ ...prev, rut: numbers }));
      }
      return;
    }

    // Resto de los campos
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNext = async () => {
    if (validateFirstStep() && validateCodes()) {
      const codesAreValid = await validateValidCodes();
      if (codesAreValid) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  }; // pursuit of happiness - Steve Aoki remix

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // --------------------------
  // MANEJO DE CLIENTE
  // --------------------------
  const handleClienteName = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({ ...prev, nombre_cliente: value }));

    if (value.length > 0) {
      const sugerencias = clienteName.filter((nombre) =>
        nombre[0].toLowerCase().includes(value.toLowerCase())
      );
      setClienteSugerencias(sugerencias);
    } else {
      setClienteSugerencias([]);
    }
  };

  const handleClienteSelect = async (id) => {
    try {
      const response = await api.get(`/clientes?id=${id}`);
      const data = response.data[0];
      setFormData((prev) => ({
        ...prev,
        nombre_cliente: data.nombre_cliente,
        rut: data.rut,
        giro: data.giro,
        direccion: data.direccion,
        contacto: data.contacto,
        pais: data.pais,
        ciudad: data.ciudad,
        nombre_de_contacto: data.nombre_de_contacto,
        telefono: data.telefono,
      }));
      setClienteSugerencias([]);
    } catch (err) {
      console.error('Error al seleccionar cliente:', err);
    }
  };// "everything that shines ain't always gonna be gold"

  const handleRutBlur = async () => {
    if (formData.rut && !isExtranjero) {
      try {
        const response = await api.get(`/clientes?rut=${formData.rut}`);
        if (response.data && response.data.length > 0) {
          const cliente = response.data[0];
          setFormData((prev) => ({
            ...prev,
            nombre_cliente: cliente.nombre_cliente,
            giro: cliente.giro,
            direccion: cliente.direccion,
            contacto: cliente.contacto,
            pais: cliente.pais,
            ciudad: cliente.ciudad,
            nombre_de_contacto: cliente.nombre_de_contacto,
            telefono: cliente.telefono,
          }));
        }
      } catch (error) {
        console.error('Error al buscar cliente:', error);
      }
    }
  };

  const getCode = async (code) => {
    const item = await api.get(`/codigos?codigo=${code}`);
    console.log(item);
    const item_data = item.data;
    return item_data.id_stocks;
  };

  const invalidateCode = async (code) => {
    const item = await api.put(`/codigos/${code}`, {});
    console.log(item);
  };

  // --------------------------
  // GUARDAR (SIMPLIFICADO / DEMO)
  // --------------------------

  const guardarSalida = async (id_cliente, items) => {
    try {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const producto = productos[i];
        const precioBase = parseNumericValue(producto.precio);
        const iva = precioBase * 0.19;
        const precioTotal = precioBase + iva;
        
        const updatedData = {
          ...formData,
          codigo: item.codigo,
          precio: precioBase,
          IVA: Math.round(iva),
          precio_total: Math.round(precioTotal),
        };
        
        await api.post('/salidasProductos', {
          ...((({ id, ...rest }) => rest)(item)),
          ...updatedData,
          centro_de_costos: formData.centro_de_costos,
          sede: formData.sede,
          id_cliente: id_cliente,
          cantidad: 1
        });
      }
      console.log('Salidas guardadas');
    } catch (error) {
      console.error('Error al guardar salidas:', error);
    }
    for (const producto of productos) {
      await invalidateCode(producto.codigo);
    }
  };

  const handleNewCliente = async () => {
    if (!validateNewCliente()) return;
    try {
      const response = await api.get(`/clientes?rut=${formData.rut}`);
      if (formData.rut === 'extranjero' || response.data.length === 0) {
        const responseCliente = await api.post('/clientes', formData);
        setRutError('');
        return responseCliente.data.id;
      } else {
        setRutError('Cliente ya registrado');
        throw new Error('Cliente ya registrado');
      }
    } catch (error) {
      setRutError(error.message || 'Error al crear/verificar el cliente');
      throw error;
    }
  };

  const handleClienteRut = async () => {
    try {
      if (formData.rut === 'extranjero') {
        setRutError('Cliente extranjero no encontrado');
        throw new Error('Cliente extranjero no encontrado');
      }
      const responseCliente = await api.get(`/clientes?rut=${formData.rut}`);
      if (!responseCliente.data || responseCliente.data.length === 0) {
        setRutError('El cliente no existe. Por favor, registre un nuevo cliente.');
        throw new Error('Cliente no encontrado');
      }
      setRutError('');
      return responseCliente.data[0].id;
    } catch (error) {
      setRutError(error.message || 'Error al verificar el cliente');
      throw error;
    }
  };

  const handleGuardar = async () => {
    if (!validateFirstStep()) return;
    
    const items = [];
    let clienteId;

    try {
      setLoading(true);
      
      // Validar RUT
      if (formData.rut === '') {
        setRutError('El RUT es obligatorio');
        return;
      }

      // Intentar obtener/crear cliente
      if (showNewCliente) {
        clienteId = await handleNewCliente();
      } else {
        clienteId = await handleClienteRut();
      }

      // Procesar todos los productos
      setLoading(true);
      for (const producto of productos) {
        const id_stocks = await getCode(producto.codigo);
        const item = (await api.get(`/stocks?id=${id_stocks}`)).data.items[0];
        
        // Actualizar stock
        await api.put(`/stocks/${item.id}?cantidad=${item.cantidad - 1}`);
        items.push(item);
      }

      if (items.length === 0) {
        throw new Error('No hay productos para guardar');
      }

      // Guardar todas las salidas
      await guardarSalida(clienteId, items);

      // Mostrar mensaje de éxito
      setDialogMessage('Salida guardada exitosamente');
      setDialogActions([
        <Button 
          key="aceptar"
          onClick={() => {
            setOpenDialog(false);
            navigate('/');
          }}
        >
          Aceptar
        </Button>
      ]);
      setOpenDialog(true);
      setRutError('');

    } catch (error) {
      console.error('Error:', error);
      setDialogMessage(`Error al guardar la salida: ${error.message || 'Error desconocido'}`);
      setDialogActions([
        <Button key="cerrar" onClick={() => setOpenDialog(false)}>Cerrar</Button>
      ]);
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------
  // VALIDACIÓN
  // --------------------------
  const validateFirstStep = () => { // heart to heart - Mac DeMarco
    const requiredFields = [
      { name: 'fecha_de_salida', label: 'Fecha de Salida' },
      { name: 'fecha_de_nota_venta', label: 'Fecha de Nota de Venta' },
      { name: 'IVA', label: 'IVA' },
      { name: 'precio_total', label: 'Precio Total' },
    ]; // RIP Mac Miller

    const newErrors = {}; // we are in this together - NIN

    // Validar campos del formulario general
    requiredFields.forEach((field) => {
      if (!formData[field.name] || formData[field.name].toString().trim() === '') {
        newErrors[field.name] = `${field.label} es obligatorio`;
      }
    });

    // Validar productos
    const productosValidos = productos.every((producto, index) => {
      if (!producto.codigo || producto.codigo.trim() === '') {
        newErrors[`codigo_${index}`] = `Código del producto ${index + 1} es obligatorio`;
        return false;
      }
      if (!producto.precio || producto.precio.trim() === '') {
        newErrors[`precio_${index}`] = `Precio del producto ${index + 1} es obligatorio`;
        return false;
      }
      return true;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && productosValidos; // What's the use? - Mac Miller
  };

  const validateCodes = () => {
    const requiredFields = productos.map((producto, index) => ({
      name: `codigo_${index}`,
      label: `Código del producto ${index + 1}`,
      value: producto.codigo
    }));

    const newErrors = {};

    // Validar campos requeridos
    requiredFields.forEach((field) => {
      if (!field.value || field.value.toString().trim() === '') {
        newErrors[`codigo_${field.name}`] = `${field.label} es obligatorio`;
      }
    });

    // Validar códigos duplicados
    const codigos = productos.map(p => p.codigo);
    codigos.forEach((codigo, index) => {
      if (codigo && codigos.indexOf(codigo) !== index) {
        newErrors[`codigo_${index}`] = 'Código duplicado';
      }
    });

    console.log(productos);
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateValidCodes = async () => {
    const requiredFields = productos.map((producto, index) => ({
      name: `codigo_${index}`,
      label: `Código del producto ${index + 1}`,
      value: producto.codigo
    }));

    const newErrors = {};

    try {
      for (const field of requiredFields) {
        const response = await api.get(`/codigos?codigo=${field.value}`);
        console.log(response.data);
        if (response.data === null) {
          newErrors[`codigo_error_${field.value}`] = `${field.label} no es un código válido, no existe en el inventario`;
        }
        else if (response.data.valid === false) {
          newErrors[`codigo_error_${field.value}`] = `${field.label} no es un código válido, ya ha sido vendido`;
        }
      }
    } catch (error) {
      console.error('Error al validar códigos:', error);
      return false;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const validateNewCliente = () => {
    // Lógica para validar el nuevo cliente
    const requiredFields = [
      { name: 'rut', label: 'RUT' },
      { name: 'nombre_cliente', label: 'Nombre del Cliente' },
      { name: 'giro', label: 'Giro' },
      { name: 'direccion', label: 'Dirección' },
      { name: 'contacto', label: 'Correo del contacto' },
      { name: 'pais', label: 'País' },
      { name: 'ciudad', label: 'Ciudad' },
      { name: 'nombre_de_contacto', label: 'Nombre del Contacto' },
      { name: 'telefono', label: 'Teléfono de Contacto' },
    ];

    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field.name] || formData[field.name].toString().trim() === '') {
        newErrors[field.name] = `${field.label} es obligatorio`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Agregar nuevo manejador para productos
  const handleProductoChange = (index, field, value) => {
    const newProductos = [...productos];
    if (field === 'precio') {
      const cleanValue = value.replace(/[^\d.]/g, '');
      const numValue = parseNumericValue(cleanValue);
      if (!isNaN(numValue)) {
        newProductos[index][field] = numValue.toLocaleString('es-CL');
      }
    } else {
      newProductos[index][field] = value;
    }
    setProductos(newProductos);
  };

  // Modificar handleCantidadProductosChange para incluir límite
  const handleCantidadProductosChange = (e) => {
    const cantidad = Math.min(parseInt(e.target.value) || 1, 15); // Limitar a 15
    setCantidadProductos(cantidad);
    
    // Actualizar array de productos
    const newProductos = Array(cantidad).fill().map((_, index) => 
      productos[index] || { codigo: '', precio: '' }
    );
    setProductos(newProductos);
  };

  // --------------------------
  // RENDER
  // --------------------------
  if (loading) return <p>Cargando...</p>;
  return (
    <Container>
      <Paper elevation={3} className="form-container">
        <Stepper activeStep={activeStep} className="form-stepper">
          <Step>
            <StepLabel>Datos del Producto</StepLabel>
          </Step>
          <Step>
            <StepLabel>Datos del Cliente</StepLabel>
          </Step>
        </Stepper>

        <form onSubmit={handleSubmit}>
          {/* Paso 1: Datos del Producto */}
          {activeStep === 0 ? (
            <>
              <Typography variant="h5" gutterBottom className="form-title">
                Salida de Producto
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cantidad de productos diferentes"
                    type="number"
                    value={cantidadProductos}
                    onChange={handleCantidadProductosChange}
                    inputProps={{ 
                      min: 1,
                      max: 15 // Agregar límite máximo
                    }}
                    helperText="Máximo 15 productos"
                  />
                </Grid>

                {/* Campos dinámicos para cada producto */}
                {productos.map((producto, index) => (
                  <Grid item xs={12} container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <TextField
                        required
                        fullWidth
                        label={`Código Producto ${index + 1}`}
                        value={producto.codigo}
                        onChange={(e) => handleProductoChange(index, 'codigo', e.target.value)}
                        error={!!errors[`codigo_${index}`] || !!errors[`codigo_error_${producto.codigo}`]}
                        helperText={errors[`codigo_${index}`] || errors[`codigo_error_${producto.codigo}`]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        required
                        fullWidth
                        label="Precio"
                        value={producto.precio}
                        onChange={(e) => handleProductoChange(index, 'precio', e.target.value)}
                        InputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="IVA"
                    name="IVA"
                    type="text"
                    value={formData.IVA}
                    onChange={handleChange}
                    error={!!errors.IVA}
                    helperText={errors.IVA}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Precio Total"
                    name="precio_total"
                    type="text"
                    value={formData.precio_total}
                    onChange={handleChange}
                    error={!!errors.precio_total}
                    helperText={errors.precio_total}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Fecha de nota de venta"
                    name="fecha_de_nota_venta"
                    type="date"
                    value={formData.fecha_de_nota_venta}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.fecha_de_nota_venta}
                    helperText={errors.fecha_de_nota_venta}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Fecha de Salida"
                    name="fecha_de_salida"
                    type="date"
                    value={formData.fecha_de_salida}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.fecha_de_salida}
                    helperText={errors.fecha_de_salida}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descripción"
                    name="descripcion"
                    multiline
                    rows={4}
                    value={formData.descripcion}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        label="Centro de Costos"
                        name="centro_de_costos"
                        value={formData.centro_de_costos}
                        onChange={handleChange}
                        error={!!errors.centro_de_costos}
                        helperText={errors.centro_de_costos}
                        InputProps={{
                          endAdornment: (
                            isAdmin && (
                              <Button onClick={() => setShowNewCentroCosto(true)} sx={{ minWidth: '40px', p: 0 }}>
                                +
                              </Button>
                            )
                          ),
                        }}
                      >
                        {centrosDeCostos.map((centro) => (
                          <MenuItem key={centro.id} value={centro.nombre}>
                            {centro.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                  <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        label="Sede"
                        name="sede"
                        value={formData.sede}
                        onChange={handleChange}
                        error={!!errors.sede}
                        helperText={errors.sede}
                        InputProps={{
                          endAdornment: (
                            isAdmin && (
                              <Button onClick={() => setShowNewSede(true)} sx={{ minWidth: '40px', p: 0 }}>
                                +
                              </Button>
                            )
                          ),
                        }}
                      >
                        {sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.nombre}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={handleNext}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            // Paso 2: Datos del Cliente
            <>
              <Typography variant="h5" gutterBottom className="form-title">
                Datos del Cliente
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="RUT"
                    name="rut"
                    value={formData.rut}
                    onChange={handleChange}
                    onBlur={handleRutBlur}
                    error={!!errors.rut || !!rutError}
                    helperText={errors.rut || rutError}
                    InputProps={{
                      readOnly: isExtranjero
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isExtranjero}
                        onChange={handleChange}
                        name="isExtranjero"
                      />
                    }
                    label="Cliente Extranjero"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="proveedor-container">
                  <TextField
                    required
                    fullWidth
                    label="Nombre del Cliente"
                    name="nombre_cliente"
                    value={formData.nombre_cliente}
                    onChange={handleClienteName}
                    error={!!errors.nombre_cliente}
                    helperText={errors.nombre_cliente}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={() => setShowNewCliente(!showNewCliente)}
                          sx={{ minWidth: '40px', p: 0 }}
                        >
                          {showNewCliente ? '-' : '+'}
                        </Button>
                      ),
                    }}
                  />
                  {clienteSugerencias.length > 0 && (
                    <div className="proveedor-sugerencias">
                      {clienteSugerencias.map((sugerencia, index) => (
                        <div
                          key={index}
                          onClick={() => handleClienteSelect(sugerencia[1])}
                          className="sugerencia-item"
                        >
                          {sugerencia[0]}
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>

                {showNewCliente && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Giro"
                        name="giro"
                        value={formData.giro}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Dirección"
                        name="direccion"
                        value={formData.direccion}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Correo del contacto"
                        name="contacto"
                        value={formData.contacto}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="País"
                        name="pais"
                        value={formData.pais}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Ciudad"
                        name="ciudad"
                        value={formData.ciudad}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Nombre del Contacto"
                        name="nombre_de_contacto"
                        value={formData.nombre_de_contacto}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Teléfono de Contacto"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <div className="button-container">
                <Button variant="outlined" onClick={handleBack} className="secondary-button">
                  Atrás
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handleGuardar}
                >
                  Guardar
                </Button>
              </div>
            </>
          )}
        </form>
      </Paper>

      {/* Dialog para agregar NUEVA SEDE */}
      <Dialog open={showNewSede} onClose={() => setShowNewSede(false)}>
        <DialogTitle>Agregar Nueva Sede</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Sede"
            fullWidth
            value={nuevaSede}
            onChange={(e) => setNuevaSede(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={() => setShowNewSede(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleGuardarSede}
            color="primary"
            variant="contained"
            disabled={!nuevaSede.trim()}
          >
            Guardar Sede
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para agregar NUEVO CENTRO DE COSTO */}
      <Dialog open={showNewCentroCosto} onClose={() => setShowNewCentroCosto(false)}>
        <DialogTitle>Agregar Nuevo Centro de Costo</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Centro de Costo"
            fullWidth
            value={nuevoCentroCosto}
            onChange={(e) => setNuevoCentroCosto(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={() => setShowNewCentroCosto(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleGuardarCentroCosto}
            color="primary"
            variant="contained"
            disabled={!nuevoCentroCosto.trim()}
          >
            Guardar Centro de Costo
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {dialogMessage.includes('Error') ? 'Error' : 'Éxito'}
        </DialogTitle>
        <DialogContent>
          {dialogMessage}
        </DialogContent>
        <DialogActions>
          {dialogActions}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SalidaInsumos;
