import React, { useState, useEffect } from 'react';
import { getPermissions } from './utils';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './assets/logo.png';

function Navbar() {
  const [showEntradaDropdown, setShowEntradaDropdown] = useState(false);
  const [showReportesDropdown, setShowReportesDropdown] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      const userPermissions = await getPermissions();
      setPermissions(userPermissions);
      setIsLoggedIn(!!localStorage.getItem('token'));
    };
    fetchPermissions();
  }, []); // Se ejecutará cuando el componente se monte

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    // Opcional: redirigir al usuario a la página de inicio o login
    window.location.href = '/login';
  };

  const toggleEntradaDropdown = () => {
    setShowEntradaDropdown(!showEntradaDropdown);
  };

  const toggleReportesDropdown = () => {
    setShowReportesDropdown(!showReportesDropdown);
    setShowEntradaDropdown(false);
  };

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <img 
            src={logo} 
            alt="Logo Sistema de Inventario" 
            className="nav-logo"
          />
        </Link>
      </div>
      <div className="nav-buttons">
        {permissions.includes('admin') && (
          <div className="admin-dropdown">
            <Link to="/admin">
              <button className="admin-btn">
                Admin
              </button>
            </Link>
          </div>
        )}
        
        {(permissions.includes('reportes') || permissions.includes('admin')) && (
          <div className="reportes-dropdown">
            <button 
              className={`reportes-btn ${showReportesDropdown ? 'active' : ''}`}
              onClick={toggleReportesDropdown}
            >
              Reportes
            </button>
            {showReportesDropdown && (
              <div className="dropdown-content">
                <Link to="/reportes" onClick={() => setShowReportesDropdown(false)}>Reportes</Link>
                <div className="nested-dropdown">
                  <Link to="/historial" onClick={(e) => e.preventDefault()}>Historial</Link>
                  <div className="nested-dropdown-content">
                    <Link to="/historial-entrada" onClick={() => setShowReportesDropdown(false)}>Entrada</Link>
                    <Link to="/historial-salida" onClick={() => setShowReportesDropdown(false)}>Salida</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {(permissions.includes('stocks') || permissions.includes('admin')) && (
          <div className="stocks-dropdown">
            <Link to="/stocks">
              <button className="stocks-btn">
                Stocks
              </button>
            </Link>
          </div>
        )}

        {(permissions.includes('entrada') || permissions.includes('admin')) && (
          <div className="entrada-dropdown">
            <button 
              className={`entrada-btn ${showEntradaDropdown ? 'active' : ''}`}
              onClick={toggleEntradaDropdown}
            >
              Entrada
            </button>
            {showEntradaDropdown && (
              <div className="dropdown-content">
                <Link to="/entrada-insumos" onClick={() => setShowEntradaDropdown(false)}>Productos</Link>
                <Link to="/entrada-equipos" onClick={() => setShowEntradaDropdown(false)}>Equipos</Link>
              </div>
            )}
          </div>
        )}

        {(permissions.includes('salida') || permissions.includes('admin')) && (
          <div className="salida-dropdown">
            <Link to="/salida-productos">
              <button className="salida-btn">
                Salida
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="login-button-container">
        {isLoggedIn ? (
          <button className="login-btn" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        ) : (
          <Link to="/login">
            <button className="login-btn">
              Iniciar Sesión
            </button>
          </Link>
        )}
      </div>
    </nav>
  );
}

export default Navbar; 