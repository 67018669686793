import React, { useEffect, useRef } from "react";

const CodigoBarra = ({ value }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    // Función para cargar el script dinámicamente
    const loadJsBarcodeScript = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/jsbarcode@3.11.0/dist/barcodes/JsBarcode.code128.min.js";
      script.async = true;
      script.onload = () => {
        if (window.JsBarcode && barcodeRef.current) {
          // Generar el código de barras
          window.JsBarcode(barcodeRef.current, value, {
            format: "CODE128",
            lineColor: "#000",
            width: 2,
            height: 50,
            displayValue: true,
          });
        }
      };
      document.body.appendChild(script);
    };

    if (!window.JsBarcode) {
      loadJsBarcodeScript();
    } else if (barcodeRef.current) {
      // Si el script ya está cargado
      window.JsBarcode(barcodeRef.current, value, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 50,
        displayValue: true,
      });
    }
  }, [value]);

  return <svg ref={barcodeRef}></svg>; // Aquí se renderiza el código de barras
};

export default CodigoBarra;
